<template>
  <div class="web-form-container">
    <el-form :model="filterForm" :rules="rules" ref="filterForm" label-width="100px" size="small">
      <div class="form-container">
        <el-form-item label="项目名称" prop="project_name">
          <div style="display: flex">
            <el-input
              style="width: 315px"
              class="form-inupt"
              v-model.trim="filterForm.project_name"
              @input="checkNameExist"
              placeholder="请填入项目名称"
            ></el-input>
            <span v-if="!!filterForm.project_name">
              <i
                class="el-icon-check"
                v-if="isCheck === true"
                :class="{ 'color-ok': isCheck === true }"
              ></i>
              <i
                class="el-icon-close"
                v-else-if="isCheck === false"
                :class="{ 'color-error': isCheck === false }"
              ></i>
            </span>
          </div>
        </el-form-item>
        <!-- new  -->
        <!-- <el-form-item label="模板类型" prop="radioType">
          <el-radio-group class="form-radio-group" v-model="filterForm.radioType" @change="getTemplateByType">
            <el-radio label="default">默认</el-radio>
            <el-radio label="jarvis">Jarvis</el-radio>
            <el-radio label="ssr">SSR</el-radio>
            <el-radio label="bp">BlendPage</el-radio>
            <el-radio label="ssrActivity">ssr活动项目</el-radio>
          </el-radio-group>
        </el-form-item> -->
        <!-- <el-form-item label="产品线" prop="projectLine">
          <el-select
            :loading="selectLoading"
            v-model="filterForm.projectLine"
            placeholder="请选择"
            @change="projectLineChange"
          >
            <el-option
              v-for="item in projectLineGroupOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <!-- <el-form-item label="应用系统" prop="application">
          <el-select
            :loading="selectLoading"
            v-model="filterForm.application"
            placeholder="请先选择产品线"
          >
            <el-option
              v-for="item in applicationList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item v-if="showInput" label="Gitlab绑定" class="one-application">
          <el-select
            v-model="gitlabValue"
            filterable
            remote
            reserve-keyword
            clearable
            placeholder="请输入关键词"
            :remote-method="remoteMethod"
            @change="gitlabChange"
            :loading="loading"
          >
            <el-option v-for="item in options" :key="item.id" :label="item.url" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!showInput" label="模板选择">
          <el-select v-model="templateId" placeholder="请选择">
            <el-option label="不使用模板" value="none"></el-option>
            <el-option
              v-for="item in templateOption"
              :key="item.gitlab_id"
              :label="item.template_name"
              :value="item.gitlab_id"
            >
            </el-option>
          </el-select>
          <!-- <el-button icon="el-icon-circle-plus-outline" style="marginLeft:15px;" type="text" @click="handleNewTemplate">创建模版</el-button> -->
        </el-form-item>
        <el-form-item label="分组选择" prop="group_id">
          <el-select v-model.number="filterForm.group_id" placeholder="请选择">
            <el-option
              v-for="item in groupOptions"
              :key="item.id"
              :label="item.group_name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目描述" prop="project_desc">
          <el-input
            :autosize="{ minRows: 2, maxRows: 2 }"
            type="textarea"
            v-model="filterForm.project_desc"
            placeholder="项目描述"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-dialog
      :append-to-body="true"
      :close-on-click-modal="false"
      :close-on-press-escape="true"
      :class="dialog.className"
      :title="dialog.title"
      :visible="dialog.visible"
      :width="dialog.width"
      @close="handleDialogClose"
      center
    >
      <component
        :inject-data="dialogInjectData"
        :is="dialog.component"
        @close="handleDialogClose"
        @refleshType="getTemplateByType"
      />
    </el-dialog>
  </div>
</template>

<script>
import api from "../api";
import { dialogMixin } from "@/mixins";
// import newTemplate from "./newTemplate.vue"
// 新增项目字段
const defaultForm = {
  project_name: "",
  gitlab_name: "",
  group_id: "",
  gitlab_url: "",
  gitlab_id: "",
  project_desc: "",
  radioType: "default",
  projectLine: "",
  application: "",
};
export default {
  name: "webForm",
  mixins: [dialogMixin],
  props: {
    projectType: {
      type: String,
      default: "web",
    },
    slug: {
      type: String,
      default: "",
    },
    submitLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showInput() {
      return (
        this.filterForm.radioType === "default" ||
        this.filterForm.radioType === "jarvis" ||
        this.filterForm.radioType === "bp" ||
        this.templateId === "none"
      );
    },
    isLoading: {
      get() {
        return this.submitLoading;
      },
      set(val) {
        this.$emit("update:submitLoading", val);
      },
    },
  },
  data() {
    return {
      filterForm: Object.assign({}, defaultForm),
      rules: {
        project_name: [
          { required: true, message: "项目名称不能为空", trigger: "blur" },
          { max: 10, message: "项目名称不能超过10个字", trigger: "blur" },
        ],
        group_id: [{ required: true, message: "请选择分组", trigger: "change" }],
        project_desc: [
          {
            max: 50,
            message: "项目描述不能超过50个字",
            trigger: "blur",
          },
        ],
        // projectLine: [{ required: true, message: "请选择产品线", trigger: "change" }],
        // application: [{ required: true, message: "请选择应用系统", trigger: "change" }],
      },
      selectLoading: false,
      loading: false,
      // projectLineGroupOptions: [], // 产品线
      // applicationList: [], // 应用系统
      gitlabValue: "", // gitlab地址
      options: [], // gitlab地址列表
      // templateId: "", // 模板id
      // templateOption: [], // 模板列表
      groupOptions: [], // 分组列表
      dialogInjectData: {},
      isCheck: "",
      timer: "",
    };
  },
  mounted() {
    this.getManageList();
    // this.getProjectLine();
  },
  methods: {
    async checkNameExist() {
      this.isCheck = "";
      clearTimeout(this.timer);

      this.timer = setTimeout(async () => {
        const res = await api.checkNameExist({ project_name: this.filterForm.project_name });
        if (res.code !== 200) {
          this.isCheck = false;
        } else {
          if (res.message == "项目名称已存在") {
            this.isCheck = false;
            this.$message.error(res.message);
          } else {
            this.isCheck = true;
          }
          // this.$message.success(res.message);
        }
      }, 500);
    },
    // 获取分组列表
    async getManageList() {
      try {
        const { code, message, data } = await api.manageList();
        if (code === 200) {
          this.groupOptions = data;
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        this.$message.error(e);
      }
    },
    // 获取产品线
    async getProjectLine() {
      try {
        this.selectLoading = true;
        const { code, message, data } = await api.getProjectLineList();
        this.selectLoading = false;
        if (code === 200) {
          this.projectLineGroupOptions = data;
        } else {
          this.$message.error(message);
        }
      } catch (e) {
        this.$message.error(e);
        this.selectLoading = false;
      }
    },
    // 业务线改变触发
    async projectLineChange(v) {
      if (v) {
        try {
          this.applicationList = [];
          this.filterForm.application = "";
          this.selectLoading = true;
          const { code, message, data } = await api.getApplication({ product_line_id: v });
          if (code === 200) {
            this.applicationList = data;
          } else {
            this.$message.error(message);
          }
          this.selectLoading = false;
        } catch (e) {
          this.$message.error(e);
          this.selectLoading = false;
        }
      }
    },
    // 远程搜索gitlab信息
    async remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const { code, message, data } = await api.getRavenURLList({ key_word: query });
        this.loading = false;
        if (code === 200) {
          this.options = data;
        } else {
          this.$message.error(message);
        }
      } else {
        this.options = [];
      }
    },
    // gitlab绑定值改变触发
    gitlabChange(v) {
      const item = this.options.find((item) => item.id === v);
      if (item) {
        const { id, url, name } = item;
        this.filterForm.gitlab_name = name;
        this.filterForm.gitlab_url = url;
        this.filterForm.gitlab_id = id;
      } else {
        this.filterForm.gitlab_name = "";
        this.filterForm.gitlab_url = "";
        this.filterForm.gitlab_id = "";
      }
    },
    // 根据类型获取模板类型
    async getTemplateByType() {
      const res = await api.getTemplateByType({ template_type: this.filterForm.radioType });
      if (res) {
        this.$nextTick(() => {
          this.templateOption = res.data;
          if (res.data.length) {
            this.templateId = res.data[0].gitlab_id;
          } else {
            this.templateId = "";
          }
        });
      }
    },
    // 打开新增模板的弹窗
    // handleNewTemplate () {
    //   this.dialogInjectData.radioType = this.filterForm.radioType;
    //   this.setDialog({
    //     visible: true,
    //     component: newTemplate,
    //     top: '200px',
    //     title: '新增模板',
    //     width: '600px'
    //   })
    // },
    // 关闭弹窗
    async handleDialogClose() {
      this.setDialog();
    },
    // 表单提交
    submitForm() {
      this.$refs["filterForm"].validate((valid) => {
        if (valid) {
          this.defineData();
        } else {
          return false;
        }
      });
    },
    // 格式化数据
    async defineData() {
      // this.filterForm.group_id = this.projectGroup;
      // this.filterForm.template_type = this.filterForm.radioType;
      this.isLoading = true;
      // const template_id = this.templateId !== "none" ? this.templateId : "";
      const params = Object.assign({}, this.filterForm, {
        slug: this.slug,
        project_type: this.projectType,
        // template_id,
      });
      if (params.template_id) {
        const res = await this.$confirm(
          "依赖模板生成项目，必须在内网环境（或连接零信任进行），请确认继续？",
          "新增模板项目提示",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          },
        );
        if (res !== "confirm") {
          this.$message.info("已取消！");
          this.isLoading = false;
          return false;
        }
      }
      // 处理产品线和应用系统的name
      // const projectLine = this.projectLineGroupOptions.find(
      // (item) => item.project_id === params.projectLine,
      // );
      // const application = this.applicationList.find((item) => item.model_id === params.application);
      // params.product_line_name = projectLine?.project_name;
      // params.product_line_id = projectLine?.project_id;
      // params.system_name = application?.model_name;
      // params.system_id = application?.model_id;
      let res = await api.newProject(params);
      if (res && res.code === 200) {
        this.$message({
          type: "success",
          message: "新增成功",
        });
        this.$router.replace(`/project?pid=${res.data.id}`);
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.web-form-container {
  padding: 5px 25px 5px 0px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .color-ok {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white;
    background-color: green;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-left: 10px;
  }
  .color-error {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    color: white;
    background-color: rgb(174, 5, 5);
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin-left: 10px;
  }
}
.one-application {
  ::v-deep .el-select {
    width: 100%;
  }
}
</style>
