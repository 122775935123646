const PROD_CONFIGURE = {
  micoSystemList: [
    {
      platformName: "A·PAGE",
      microName: "main",
      activeRule: "/app-main",
      entry: "https://apage.cc:8080",
    },
    {
      platformName: "工作台",
      microName: "xps",
      activeRule: "/app-xps",
      entry: "https://medusa-instance.xsyxsc.com/xps",
    },
    {
      platformName: "配置中心",
      microName: "configuration-center",
      activeRule: "/app-configuration-center",
      entry: "https://medusa-instance.xsyxsc.com/configuration",
    },
    // {
    //   platformName: '地宝代码扫描',
    //   microName: 'micro-name',
    //   activeRule: '/app-micro-name',
    //   entry: 'https://medusa-instance.xsyxsc.com/deebot-web#/'
    //   // entry: 'http://localhost:8055/deebot-web#/'
    // },
    {
      platformName: "raven自动发布平台",
      microName: "raven-client",
      activeRule: "/app-raven-client",
      entry: "https://publish.xsyxsc.cn",
    },
    {
      platformName: "Melons移动应用托管平台",
      microName: "melons",
      activeRule: "/app-melons",
      entry: "https://melons.xsyxsc.com",
    },
  ],
};
export default PROD_CONFIGURE;
