/** @format */

"use strict";

import ajax from "@utils/request.js";

const login = {
  /**
   * 获取用户信息
   * @param {*} params
   * @returns
   */
  userinfo: (params) => {
    let data = ajax.post(`/user/userinfo`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 获取验证码
   * @param {*} params
   * @returns
   */
  getCaptcha: (params) => {
    let data = ajax.post(`/user/captcha`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 登录
   * @param {*} params
   * @returns
   */
  oauth: (params) => {
    let data = ajax.post(`/user/oauth`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 注册
   * @param {*} params
   * @returns
   */
  registry: (params) => {
    let data = ajax.post(`/user/registry`, params).then((response) => {
      return response.data;
    });
    return data;
  },
};

export default login;
