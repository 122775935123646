/**
 * request 工具类
 * 基于axios进行一个封装 (https://github.com/axios/axios)
 * 便于后续统一的针对request函数做修改
 *
 * @format
 */

import Axiox from "axios";
import qs from "qs";
// import appUtils from './appUtils';
import { Message } from "element-ui";
import store from "../store";
const BASE_URL = process.env.VUE_APP_BASE_API;

const Api = {
  /**
   * 对axios api的通用封装
   *
   * @param method 要请求的方法
   * @param url 要请求的url
   * @param baseURL 将自动加在 `url` 前面，除非 `url` 是一个绝对 URL。
   *        它可以通过设置一个 `baseURL` 便于为 axios 实例的方法传递相对 URL
   * @param data 要发送的数据
   * @param timeout 超时时间
   * @param headers 额外设置的http header
   * @param params 额外需要携带的参数
   *
   * 更多详细参数请参考：https://github.com/axios/axios
   * @returns {Promise}
   */
  request: async (configs = {}) => {
    const results = await Axiox.request(configs).then((response) => {
      return response;
    });
    return results;
  },

  /**
   * GET封装
   */
  get: async (url, opts = {}) => {
    let _opts = {
      baseURL: `${BASE_URL}`,
      headers: Object.assign({}, opts.headers, { Authorization: store.getters.token }), // 如果需要默认headers,在这里处理
    };

    // get请求需要再opts中携带 {params：}参数, 对此进行处理
    if (!opts.params) {
      // 如果未携带 params 参数, 则认为默认全部是params
      _opts.params = { ...opts };
    } else {
      _opts = Object.assign(opts, _opts);
    }

    let resData = await Axiox.get(url, { ..._opts }).catch(function (error) {
      if (error.response && error.response.status !== 200) {
        // 处理http状态码错误
        console.log("=== GET.HTTP级别.错误统一处理逻辑 ===");

        if ([401, 401001].includes(error.response.status)) {
          Message({
            message: error.response.message
              ? error.response.message
              : "登录信息过期,即将前往登录页",
            type: "warning",
          });
          // 前往登录页
          location.href = "/home";
          return false;
        } else {
          Message({
            message: error.response.message ? error.response.message : "服务器发生错误",
            type: "error",
          });
        }
        return false;
      }
    });

    // 业务状态码处理
    if (resData.data && resData.data.code != 200) {
      console.log("=== GET.业务级别.错误统一处理逻辑 ===");
      let msgStr = resData.data.message ? resData.data.message : "服务器发生错误";
      Message({
        message: `${msgStr}`,
        type: "error",
      });
      return false;
    }
    return resData;
  },

  /**
   * POST 封装
   */
  post: async (url, data, opts = {}) => {
    let _opts = {
      baseURL: `${BASE_URL}`,
      headers: Object.assign({}, opts.headers, { Authorization: store.getters.token }), // 如果需要默认headers,在这里处理
    };
    _opts = Object.assign(opts, _opts);
    let resData = await Axiox.post(url, data, { ..._opts }).catch(function (error) {
      if (error.response && error.response.status !== 200) {
        // 处理http状态码错误
        console.log("=== POST.HTTP级别.错误统一处理逻辑 ===");
        if ([401, 401001].includes(error.response.status)) {
          Message({
            message: error.response.message
              ? error.response.message
              : "登录信息过期,即将前往登录页",
            type: "warning",
          });
          // 前往登录页
          location.href = "/home";
          return false;
        } else {
          Message({
            message: error.response.message ? error.response.message : "服务器发生错误",
            type: "error",
          });
        }
        return false;
      }
    });

    // 业务状态码处理
    if (resData.data && resData.data.code != 200) {
      console.log("=== POST.业务级别.错误统一处理逻辑 ===");
      let msgStr = resData.data.message ? resData.data.message : "服务器发生错误";
      Message({
        message: `${msgStr}`,
        type: "error",
      });
      return false;
    }

    return resData;
  },

  // formData提交
  formDataRequest: async (url, data, opts = {}) => {
    data = qs.stringify(data, { indices: false });
    let options = {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    };
    Object.assign(options, opts);
    return await Axiox.post(url, data, { ...opts });
  },
};

export default Api;
