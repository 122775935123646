import DynamicStore from '@lmio/dynamic-store';
import api from '../../views/home/api'

const state = {
  messageList: [], // 总消息列表
  latestMessageList: [], // 最新消息列表 
  historyMessageList: [], // 历史消息列表
  latestReadMessageId: '', // 最新已读消息id
  currentMessageTotal: 0,// 当前返回的消息数量
  isSyncMessageSetting: false // 当前消息是否同步
}



const actions = {
  // 获取消息列表
  async getMessageList ({ commit, dispatch }, { isRefresh }) {
    let _res = await api.getMessageList();
    if (_res.code == "200") {
      commit("messageList", _res.data);
      // 判断是否需要更新消息状态
      if (isRefresh) {
        dispatch('refreshMessageStatus');
      }
    }
  },
  // 同步项目成员消息配置
  async syncMemberMessageSetting ({ commit, state }, { project_id, slug }) {
    if (!state.isSyncMessageSetting) {
      let _res = await api.syncMemberMessageSetting({ project_id, slug });
      if (_res) {
        commit("isSyncMessageSetting", true);
      }
    }
  },
  // 滚动加载消息列表
  async scrollLoadingMessage ({ commit, dispatch, state }, { isRefresh, pageNum, pageSize }) {
    let _res = await api.getMessageList({ pageNum, pageSize });
    if (_res.code == "200" && _res.data && _res.data.length) {
      commit("messageList", state.messageList.concat(_res.data));
      // 判断是否需要更新消息状态
      if (isRefresh) {
        dispatch('refreshMessageStatus');
      }
      commit("currentMessageTotal", _res.data.length);
    }
  },
  // 更新消息状态
  refreshMessageStatus (ctx, payload) {
    ctx.dispatch('getLatestMessageList');
    ctx.dispatch('getHistoryMessageList');
  },
  // 新增最新消息
  addLatestMessage ({ state, dispatch, commit }, data) {
    // 格式化参数
    const params = {
      message_content: data.content,
      message_name: data.message_name,
      message_type: data.message_type,
      id: data.message_id,
      project_id: data.project_id,
      project_name: data.project_name,
      slug: data.slug,
      trigger_user: data.trigger_user,
      createdAt: new Date(data.time),
      updatedAt: new Date(data.time)
    }
    const messageList = state.messageList;
    messageList.unshift(params);
    commit("messageList", messageList);
    dispatch('refreshMessageStatus');
  },
  // 获取最新消息列表
  getLatestMessageList ({ state, commit }) {
    let latestMessageList = [];
    // 如果有最新消息id正常查找
    if (state.latestReadMessageId) {
      state.messageList.some(item => {
        if (item.id !== state.latestReadMessageId) {
          latestMessageList.push(item);
          return false;
        }
        return true;
      })
    } else {
      // 如果没有最新消息id，选择前五条的为最新消息
      latestMessageList = state.messageList.slice(0, 5);
    }
    commit("latestMessageList", latestMessageList);
  },
  // 获取历史消息列表
  getHistoryMessageList ({ state, commit }) {
    let historyMessageList = [];
    // 如果有最新消息id正常查找
    if (state.latestReadMessageId) {
      const index = state.messageList.findIndex(item => {
        return item.id === state.latestReadMessageId;
      })
      historyMessageList = state.messageList.slice(index);
    } else {
      // 如果没有最新消息id，选择五条后面的为历史消息
      historyMessageList = state.messageList.slice(5);
    }
    commit("historyMessageList", historyMessageList);
  },
  // 设置最新消息id
  setLatestReadMessageId ({ commit }, id) {
    commit("latestReadMessageId@persistence", id);
  },
}


// tips 需要提前执行 先于state初始化
const messageDS = new DynamicStore(state, {
  actions,
  persistence: true,
  mutationController: ['set', 'persistence']
});

export default messageDS.exportModule();
