// Store State 统一出口
const getters = {
  userInfo: state => state.user.userInfo,
  token: state => state.user.token,
  // roles: state => state.user.roles,
  // routes: state => state.permission.routes,
  // addRoutes: state => state.permission.addRoutes,
  opened: state => state.app.opened,
  // showDriver: state => state.app.showDriver,
  projectInfo: state => state.project.projectInfo,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  currentProject: state => state.project.currentProject,
  currentGroup: state => state.project.currentGroup,
  searchProjectString: state => state.project.searchProjectString,
  myFavoriteProject: state => state.project.myFavoriteProject,
  groupList: state => state.project.groupList,
}

export default getters
