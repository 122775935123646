const DEV_CONFIGURE = {
  micoSystemList: [
    {
      platformName: "A·PAGE",
      microName: "main",
      activeRule: "/app-main",
      entry: "http://localhost:3033",
      // entry: "http://localhost:3033/",
    },
    {
      platformName: "工作台",
      microName: "xps",
      activeRule: "/app-xps",
      entry: "https://medusa-instance-in.xsyxsc.com/uat/xps",
      // entry: 'http://localhost:8080/'
    },
    {
      platformName: "配置中心",
      microName: "configuration-center",
      activeRule: "/app-configuration-center",
      entry: "https://medusa-instance-in.xsyxsc.com/dev/configuration",
      // entry: 'http://localhost:9090'
    },
    {
      platformName: "地宝代码扫描",
      microName: "micro-name",
      activeRule: "/app-micro-name",
      entry: "https://medusa-instance-in.xsyxsc.com/dev/deebot-web#/",
      // entry: 'http://localhost:8055/deebot-web#/'
    },
    {
      platformName: "raven自动发布平台",
      microName: "raven-client",
      activeRule: "/app-raven-client",
      entry: "http://10.255.31.175:3001",
      // entry: 'http://localhost:9091'
    },
    {
      platformName: "Melons移动应用托管平台",
      microName: "melons",
      activeRule: "/app-melons",
      entry: "http://melons.xsyxsc.cn",
      // entry: "http://localhost:5889",
    },
  ],
};
export default DEV_CONFIGURE;
