<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="19.5749" cy="17.0461" r="15.1579" fill="#93F2FF" />
    <g style="mix-blend-mode: multiply">
      <circle cx="17.55" cy="19.071" r="15.1579" fill="#FEF99F" />
    </g>
    <path
      d="M29.2121 20.6829L34.4982 15.2439C35.1353 14.5884 34.7775 13.4872 33.8768 13.3314L25.6208 11.9029C24.463 11.7025 23.4636 10.9765 22.9153 9.93721L19.0055 2.5267C18.5789 1.71824 17.4211 1.71824 16.9945 2.52671L13.0847 9.93721C12.5364 10.9765 11.537 11.7025 10.3792 11.9029L2.12317 13.3314C1.22247 13.4872 0.864667 14.5884 1.50175 15.2439L6.78788 20.6829"
      stroke="black"
      stroke-width="1.8"
      stroke-linecap="round"
    />
    <path
      d="M27.3282 22.8025L28.7881 32.9538C28.9182 33.8586 27.9814 34.5392 27.1612 34.1358L19.6299 30.4327C18.5754 29.9142 17.3401 29.9142 16.2857 30.4327L8.75441 34.1358C7.93412 34.5392 6.9974 33.8586 7.12751 32.9538L8.58738 22.8025"
      stroke="black"
      stroke-width="1.8"
    />
    <path
      d="M28.1936 28.8525L27.5176 23.36C27.389 22.315 26.4332 21.5753 25.3894 21.7129L23.0428 22.0224C22.9976 22.0283 22.9516 22.0216 22.9099 22.0031V22.0031C19.7105 20.5745 16.0535 20.5741 12.854 22.0027V22.0027C12.8118 22.0215 12.7651 22.0281 12.7194 22.0217L10.6037 21.7265C9.56288 21.5813 8.60268 22.3109 8.46378 23.3527L7.73047 28.8525"
      stroke="black"
      stroke-width="1.8"
    />
    <path d="M14 25.5526H22" stroke="#FF4B38" stroke-width="2" stroke-linecap="round" />
  </svg>
</template>

<script>
export default {
  name: "APageLogo",
};
</script>

<style lang="scss" scoped></style>
