<template>
  <div class="home">
    <div class="main-container">
      <header class="main-container-header">
        <APageLogo></APageLogo>
        <span>A·PAGE 工作台</span>
      </header>
      <div class="main-image"></div>
      <div class="info">告别繁琐无需运维</div>
      <div class="info">快速 稳定 高效的WEB项目部署体验</div>
      <el-button @click="showLogin">登录</el-button>
      <div class="main-container-panel">
        <div class="panel-container" v-for="panel in panels" :key="panel.key">
          <div class="panel-svg">
            <svg
              v-if="panel.key === 1"
              width="44"
              height="38"
              viewBox="0 0 44 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 2.08333C0 0.93275 0.93275 0 2.08333 0H17.5296L21.6962 4.16667H39.5833C40.734 4.16667 41.6667 5.09942 41.6667 6.25V19.5008C39.5881 16.5433 36.1546 14.5833 32.2917 14.5833C26.8706 14.5833 22.2948 18.4438 21.1237 23.5035C18.44 25.2721 16.6667 28.3112 16.6667 31.7708C16.6667 33.906 17.3429 35.8831 18.4929 37.5H2.08333C0.93275 37.5 0 36.5673 0 35.4167V2.08333ZM25.0031 26.2565C22.5969 26.9356 20.8333 29.1473 20.8333 31.7708C20.8333 34.935 23.3983 37.5 26.5625 37.5H38.0208C41.185 37.5 43.75 34.935 43.75 31.7708C43.75 29.1473 41.9865 26.9356 39.5802 26.2565C39.6975 22.2037 36.3494 18.75 32.2917 18.75C28.234 18.75 24.8858 22.2037 25.0031 26.2565Z"
                fill="#1CB86E"
              />
            </svg>
            <svg
              v-if="panel.key === 2"
              width="43"
              height="42"
              viewBox="0 0 43 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5146 0.38287C14.5947 -0.562359 11.4205 0.752433 10.0241 3.48556L8.34541 6.77133C8.14595 7.16174 7.82843 7.47927 7.43801 7.67872L4.15224 9.35743C1.41912 10.7538 0.104328 13.928 1.04956 16.8479L2.18593 20.3583C2.32095 20.7754 2.32095 21.2246 2.18593 21.6417L1.04956 25.1521C0.104328 28.0721 1.41912 31.2463 4.15224 32.6427L7.43801 34.3213C7.82843 34.5208 8.14595 34.8383 8.34541 35.2288L10.0241 38.5146C11.4205 41.2477 14.5947 42.5625 17.5146 41.6173L21.025 40.4808C21.4421 40.3458 21.8913 40.3458 22.3084 40.4808L25.8188 41.6173C28.7388 42.5625 31.913 41.2477 33.3094 38.5146L34.9879 35.2288C35.1875 34.8383 35.505 34.5208 35.8955 34.3213L39.1813 32.6427C41.9144 31.2463 43.2292 28.0721 42.284 25.1521L41.1475 21.6417C41.0125 21.2246 41.0125 20.7754 41.1475 20.3583L42.284 16.8479C43.2292 13.928 41.9144 10.7538 39.1813 9.35743L35.8955 7.67872C35.505 7.47927 35.1875 7.16174 34.9879 6.77133L33.3094 3.48556C31.913 0.752433 28.7388 -0.562359 25.8188 0.38287L22.3084 1.51925C21.8913 1.65425 21.4421 1.65427 21.025 1.51925L17.5146 0.38287ZM10.7496 20.4944L13.6958 17.5479L19.5884 23.4406L31.3736 11.6555L34.3198 14.6018L19.5884 29.3331L10.7496 20.4944Z"
                fill="#FCE811"
              />
              <path
                d="M17.5146 0.38287C14.5947 -0.562359 11.4205 0.752433 10.0241 3.48556L8.34541 6.77133C8.14595 7.16174 7.82843 7.47927 7.43801 7.67872L4.15224 9.35743C1.41912 10.7538 0.104328 13.928 1.04956 16.8479L2.18593 20.3583C2.32095 20.7754 2.32095 21.2246 2.18593 21.6417L1.04956 25.1521C0.104328 28.0721 1.41912 31.2463 4.15224 32.6427L7.43801 34.3213C7.82843 34.5208 8.14595 34.8383 8.34541 35.2288L10.0241 38.5146C11.4205 41.2477 14.5947 42.5625 17.5146 41.6173L21.025 40.4808C21.4421 40.3458 21.8913 40.3458 22.3084 40.4808L25.8188 41.6173C28.7388 42.5625 31.913 41.2477 33.3094 38.5146L34.9879 35.2288C35.1875 34.8383 35.505 34.5208 35.8955 34.3213L39.1813 32.6427C41.9144 31.2463 43.2292 28.0721 42.284 25.1521L41.1475 21.6417C41.0125 21.2246 41.0125 20.7754 41.1475 20.3583L42.284 16.8479C43.2292 13.928 41.9144 10.7538 39.1813 9.35743L35.8955 7.67872C35.505 7.47927 35.1875 7.16174 34.9879 6.77133L33.3094 3.48556C31.913 0.752433 28.7388 -0.562359 25.8188 0.38287L22.3084 1.51925C21.8913 1.65425 21.4421 1.65427 21.025 1.51925L17.5146 0.38287ZM10.7496 20.4944L13.6958 17.5479L19.5884 23.4406L31.3736 11.6555L34.3198 14.6018L19.5884 29.3331L10.7496 20.4944Z"
                fill="#1CB86E"
              />
            </svg>
            <svg
              v-if="panel.key === 3"
              width="39"
              height="46"
              viewBox="0 0 39 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.3335 0.083313L36.452 3.88744C37.4054 4.09927 38.0835 4.94471 38.0835 5.92117V26.7269C38.0835 30.9062 35.9947 34.8091 32.5172 37.1275L19.3335 45.9166L6.14975 37.1275C2.67227 34.8091 0.583496 30.9062 0.583496 26.7269V5.92117C0.583496 4.94471 1.26168 4.09927 2.21489 3.88744L19.3335 0.083313ZM19.3335 12.5833C17.0322 12.5833 15.1668 14.4488 15.1668 16.75C15.1668 18.2922 16.0047 19.6387 17.25 20.3591L17.2502 29.25H21.4168L21.4191 20.3579C22.6633 19.6371 23.5002 18.2913 23.5002 16.75C23.5002 14.4488 21.6347 12.5833 19.3335 12.5833Z"
                fill="#1CB86E"
              />
            </svg>
          </div>
          <div class="panel-info">
            <p class="panel-info-title">{{ panel.title }}</p>
            <div class="panel-info-content">
              <p>{{ panel.firstInfo }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main-dialog" v-if='isLogin' @click='close'>
    </div>
    <div class="main-dialog-content" v-if='isLogin'>
        <Login></Login>
      </div>
  </div>
</template>

<script>
import Login from "../login";
import APageLogo from "../login/a_page_logo.vue";

export default {
  name: "Home",
  components: {
    Login,
    APageLogo,
  },
  data() {
    return {
      isLogin: false,
      panels: [
        {
          key: 1,
          title: "0门槛使用",
          firstInfo: "多环境访问限制一键就够了，2分钟完成新项目部署，迭代更便捷",
        },
        {
          key: 2,
          title: "个人免费使用",
          firstInfo: "在一定的访问范围内，完全免费！即便超出了，你可以选择我们的套餐，弹性付费。",
        },
        {
          key: 3,
          title: "云端安全，稳如泰山",
          firstInfo:
            "防火墙、入侵检测系统(IDS)、分布式拒绝服务(DDoS)防护等，可防御多种网络攻击，确保项目安全",
        },
      ],
    };
  },
  methods: {
    showLogin() {
      this.isLogin = true;
    },
    close(e) {
      e.stopPropagation();
      this.isLogin = false
    }
  },
};
</script>

<style scoped lang="scss">
.home {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "Alibaba PuHuiTi", "Arial", sans-serif;

  .main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #e5f3ffe5;
    background-image: url(https://front-xps-cdn.xsyx.xyz/libs/meidusa_new/0.0.1/homeBackground.png);
    background-repeat: no-repeat;
    background-size: cover;
    .main-container-header {
      position: fixed;
      top: 0;
      width: 100%;
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 16px;
      span {
        margin-left: 10px;
      }
    }
    .main-image {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .main-container-name {
      position: absolute;
      bottom: 80px;
      font-size: 90px;
      left: calc(250px - 165px);
      font-family: cursive;
      font-style: italic;
      letter-spacing: 20px;
      text-align: center;
    }
    .main-container-core {
      width: 500px;
      height: 500px;
    }

    .main-container-panel {
      margin-top: 7%;
      display: flex;
      gap: 26px;
      width: 100%;
      justify-content: center;
      padding: 0 60px 0 60px;
      .panel-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 36px 16px 36px 16px;
        background-color: white;
        width: 423px;
        height: 180px;
        border: 1px solid #ffffff;
        border-radius: 8px;
        .panel-svg {
          box-shadow: 0px 0px 6px 0px #122c880d;
          width: 88px;
          height: 88px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          border-radius: 50%;
        }
        .panel-info {
          height: 108px;
          width: 280px;
          display: flex;
          flex-direction: column;
          gap: 10px;
          justify-content: center;
          .panel-info-title {
            font-size: 28px;
            line-height: 28px;
            color: #1d2129;
          }
          .panel-info-content {
            color: #9faab6;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
    .info {
      color: #1d1d1f;
      font-size: 76px;
      line-height: 106px;
      text-align: center;
    }
    :deep(.el-button) {
      margin-top: 20px;
      min-width: 375px;
      height: 52px;
      margin-left: auto;
      font-size: 20px;
      line-height: 20px;
      margin-right: auto;
      background-color: #1cb86e;
      color: white;
      border-radius: 8px;
      padding-inline: 30px;
      margin-inline: auto;
      font-family: "Alibaba PuHuiTi", "Arial", sans-serif;
    }
    h1 {
      color: #1d1d1f;
      text-align: center;
      margin-bottom: 50px;
      z-index: 1;
      user-select: none;
      -webkit-margin-before: 0;
      margin-block-start: 0;
      font-size: 140px;
      font-weight: 600;
      letter-spacing: -2.8px;
      line-height: 167px;
    }
    
  }
}
.main-dialog {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: black;
  opacity: .5;
  z-index: 2023;
  
 
}
.main-dialog-content {
    position: fixed;
    z-index: 2024;
    border-radius: 10px;
    background-color: transparent;
    top: calc(50% - 300px);
    left: calc(50% - 200px);
  }
.el-header {
  background-color: #3a3a3a;
}
</style>
