function toolWatermark(txt) {
    let canvas = document.createElement('canvas')
    const dom = document.getElementById('__water-mark')
    if (dom) document.body.removeChild(dom)
    canvas.id = '__canvas'
    canvas.width = '300' // 每个水印的宽高
    canvas.height = '280'
    let ctx = canvas.getContext('2d')
    ctx.fillStyle = 'rgba(1, 1, 1, 0.4)'
    ctx.rotate((25 * Math.PI) / 180) // 偏转的角度
    ctx.globalAlpha = 0.2;
    ctx.font = "14px Microsoft YaHei"
    let txtx = txt ? txt : 'MEDUSA'
    ctx.fillText(txtx, 30, 20)  // 绘制文本 绘制开始位置
    let src = canvas.toDataURL('image/png')
    // 水印容器
    let waterMaskDiv = document.createElement('div')
    waterMaskDiv.style.position = 'absolute'
    waterMaskDiv.style.zIndex = '10000'
    waterMaskDiv.id = '__water-mark'
    waterMaskDiv.style.top = '0'
    waterMaskDiv.style.left = '0'
    waterMaskDiv.style.right = '0'
    waterMaskDiv.style.bottom = '0'
    waterMaskDiv.style.height = '100%'
    waterMaskDiv.style.width = '100%'
    waterMaskDiv.style.pointerEvents = 'none'
    waterMaskDiv.style.backgroundPosition = 'center center'

    waterMaskDiv.style.backgroundImage = 'URL(' + src + ')'
    // 水印节点插到body下 可以通过层级控制节点层次
    document.body.appendChild(waterMaskDiv)
    document.body.style.position="relative"
}
export default toolWatermark