<template>
  <div id="domPersonal" class="user-dropdown">
    <el-dropdown placement="bottom-start">
      <span class="el-dropdown-link">
        {{ userInfo.realName }}
        <i class="el-icon-caret-bottom"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item icon="el-icon-s-home" @click.native="goto('/')">
          回到首页
        </el-dropdown-item>
        <el-dropdown-item
          icon="el-icon-s-custom"
          v-if="userInfo.is_admin"
          @click.native="goto('/app-main#/adminManager')"
        >
          管理菜单
        </el-dropdown-item>
        <el-dropdown-item icon="el-icon-question" @click.native="goto('/app-main#/feedback')">
          反馈查看</el-dropdown-item
        >
        <el-dropdown-item icon="el-icon-s-order" @click.native="goto('/app-main#/helpCenter')">
          文档编辑</el-dropdown-item
        >
        <el-dropdown-item
          v-if="userInfo.is_admin"
          icon="el-icon-s-data"
          @click.native="goto('/app-main#/dataSync')"
        >
          数据同步</el-dropdown-item
        >
        <el-dropdown-item
          v-if="userInfo.is_admin"
          icon="el-icon-s-flag"
          @click.native="goto('/app-main#/publishPage')"
        >
          版本发布</el-dropdown-item
        >
        <el-dropdown-item divided icon="el-icon-switch-button" @click.native="handleLoginOut">
          退出登录
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-image style="width: 40px; height: 40px" :src="userInfo.avatar" fit="fill"> </el-image>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Api from "./api";

export default {
  computed: {
    ...mapGetters(["token", "userInfo"]),
  },
  created() {
    console.log(this.userInfo, "userInfo");
  },
  methods: {
    async handleLoginOut() {
      const { email } = this.userInfo;
      try {
        await Api.logout({ email });
        this.$message({
          message: "用户退出登陆",
          type: "warning",
          center: true,
        });
        this.$store.dispatch("user/loginOut");
        location.href = "/login";
      } catch (e) {
        console.log(e);
      }
    },
    goto(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown {
  padding: 2px 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .el-dropdown {
    padding: 12px 0;
  }

  .el-image {
    border-radius: 50%;
    margin-left: 6px;
  }
}
</style>
