/** @format */

"use strict";

import ajax from "@utils/request.js";

const user = {
  // 退出
  logout: (params) => {
    let data = ajax.post(`/user/logout`, params).then((response) => {
      return response.data;
    });
    return data;
  },
};

export default user;
