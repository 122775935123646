import DevConfig from "./dev";
import UatConfig from "./uat";
import ProdConfig from "./prod";
import { merge } from "lodash-es";
const BASE_CONFIGURE = {
  menusList: [
    {
      path: "/app-main#/",
      iconClass: "el-icon-s-home",
      name: "代码管理",
      type: ["web"],
    },
    {
      path: "/app-main",
      iconClass: "el-icon-s-open",
      name: "功能配置",
      type: ["web"],
      children: [
        {
          name: "功能注入",
          path: "/app-main#/config",
        },
        {
          name: "数据注入",
          path: "/app-main#/customJson",
        },
        // {
        //   name: "配置中心",
        //   path: "/app-configuration-center#/setting",
        // },
        // {
        //   name: "域名/网关配置",
        //   path: "/app-main#/domain",
        // },
      ],
    },
    // {
    //   name: "APP",
    //   path: "/app-melons#/app",
    //   iconClass: "el-icon-s-home",
    //   type: ["app"],
    //   children: [
    //     {
    //       name: "APP数据",
    //       path: "/app-melons#/app",
    //       iconClass: "el-icon-s-marketing",
    //     },
    //     {
    //       name: "Raven发布",
    //       path: "/app-raven-client#/app/history",
    //       iconClass: "el-icon-upload",
    //     },
    //   ],
    // },
    // {
    //   name: "热更新",
    //   path: "/app-melons#/hotUpdate",
    //   iconClass: "el-icon-s-open",
    //   type: ["patch_app"],
    //   children: [
    //     {
    //       name: "热更新数据",
    //       path: "/app-melons#/hotUpdate",
    //       iconClass: "el-icon-s-platform",
    //     },
    //     {
    //       name: "Raven发布",
    //       path: "/app-raven-client#/hotUpdate/history",
    //       iconClass: "el-icon-upload",
    //     },
    //   ],
    // },
    // {
    //   name: "离线包",
    //   path: "/app-melons#/packages",
    //   iconClass: "el-icon-s-home",
    //   type: ["packages"],
    // },
    // {
    //   name: "离线包",
    //   path: "/app-melons#/offlinePackage",
    //   iconClass: "el-icon-s-home",
    //   type: ["packages_v2"],
    // },
    // {
    //   name: "ABM兑换码统计",
    //   path: "/app-melons#/statisticsInfo",
    //   iconClass: "el-icon-data-line",
    //   type: ["app"],
    // },
    // {
    //   path: "/app-main#/webci",
    //   iconClass: "el-icon-s-platform",
    //   name: "构建发布",
    //   type: ["web"],
    // },
    // {
    //   path: "/app-raven-client#/web/history",
    //   iconClass: "el-icon-upload",
    //   name: "Raven发布",
    //   type: ["web"],
    // },
    // {
    //   path: "/app-raven-client#/wxapp/history",
    //   iconClass: "el-icon-upload",
    //   name: "Raven发布",
    //   type: ["mini_program"],
    // },
    // {
    //   path: "/app-configuration-center#/setting",
    //   iconClass: "el-icon-s-tools",
    //   name: "配置中心",
    //   type: ["mini_program", "app", "packages", "packages_v2"],
    // },
    // {
    //   path: "/app-raven-client#/app/history",
    //   iconClass: "el-icon-upload",
    //   name: "Raven发布(APP)",
    //   type: ["app"],
    // },
    // {
    //   path: "/app-raven-client#/hotUpdate/history",
    //   iconClass: "el-icon-upload",
    //   name: "Raven发布(热更新)",
    //   type: ["patch_app"],
    // },
    // {
    //   path: "/app-xps",
    //   iconClass: "el-icon-folder-add",
    //   name: "资源管理",
    //   type: ["web", "mini_program", "app", "packages", "packages_v2", "patch_app"],
    //   children: [
    //     {
    //       name: "文件上传",
    //       path: "/app-xps#/fileUpload",
    //     },
    //     {
    //       name: "文件管理",
    //       path: "/app-xps#/fileManage/projectDetail",
    //     },
    //     {
    //       name: "静态页面",
    //       path: "/app-xps#/staticPage",
    //     },
    //     {
    //       name: "XPSCDN",
    //       path: "/app-xps#/xpsCdn",
    //     },
    //   ],
    // },
    // {
    //   path: "/app-micro-name#/deebot-score/score-display",
    //   iconClass: "el-icon-sugar",
    //   name: "代码检测",
    //   type: ["web"],
    // },
    {
      path: "/app-main#/setting",
      iconClass: "el-icon-s-tools",
      name: "项目设置",
      type: ["web"],
      children: [
        {
          name: "基本信息设置",
          path: "/app-main#/projectSetting",
        },
        {
          name: "历史记录",
          path: "/app-main#/historyRecord",
        },
        {
          name: "消息通知",
          path: "/app-main#/messageNotice",
        },
        {
          name: "hook钩子配置",
          path: "/app-main#/integrations",
        },
        {
          name: "项目权限",
          path: "/app-main#/proejctPermission",
        },
      ],
    },
    {
      name: "系统设置",
      path: "/app-melons#/settings",
      iconClass: "el-icon-setting",
      type: ["app", "package", "patch_app"],
      children: [
        {
          name: "日志",
          path: "/app-melons#/logs",
          iconClass: "el-icon-tickets",
        },
        {
          name: "权限",
          path: "/app-melons#/permission",
          iconClass: "el-icon-s-custom",
        },
      ],
    },
  ],
  micoSystemList: [
    {
      platformName: "A·PAGE",
      microName: "main",
      activeRule: "/app-main",
      entry: "http://medusa-v3.cs-test.xsyxsc.cn",
    },
    {
      platformName: "工作台",
      microName: "xps",
      activeRule: "/app-xps",
      entry: "https://medusa-instance-in.xsyxsc.com/uat/medusa-micro-xps",
    },
    // {
    //   platformName: '配置中心',
    //   microName: 'configuration-center',
    //   activeRule: '/app-configuration-center',
    //   entry: 'https://medusa-instance-in.xsyxsc.com/dev/configuration'
    // }
  ],
};
let mergeConfig = {};
switch (process.env.VUE_APP_ENV) {
  case "development":
    mergeConfig = DevConfig;
    break;
  case "uat":
    mergeConfig = UatConfig;
    break;
  case "production":
    mergeConfig = ProdConfig;
    break;
}
merge(BASE_CONFIGURE, mergeConfig);

export default BASE_CONFIGURE;
