import DynamicStore from '@lmio/dynamic-store';
const state = {
  opened: true, // 菜单侧边栏展开状态 true:打开 false:关闭
  showDriver: false // 导航状态 true:需要 false:不需要
}

const actions = {}


// tips 需要提前执行 先于state初始化
const appDS = new DynamicStore(state, {
  actions,
  persistence: true,
  mutationController: ['set', 'persistence']
});

export default appDS.exportModule();
