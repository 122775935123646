<template>
  <div class="auth-container">
    <el-card class="auth-card">
      <div class="card-container">
        <div class="logo-wrap">
          <APageLogo></APageLogo>
          <span class="logo-text">A·PAGE</span>
        </div>
        <transition name="slide-fade" mode="out-in">
          <el-form
            v-if="isLogin"
            key="login-form"
            ref="loginForm"
            :model="loginForm"
            :rules="loginRules"
            label-width="0"
          >
            <el-form-item prop="email">
              <el-input v-model="loginForm.email" placeholder="邮箱" :prefix-icon="emailIcon">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                type="password"
                placeholder="密码"
                :prefix-icon="passwordIcon"
                show-password
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <div class="auth-btn">
                <el-button type="primary" @click="submitLoginForm">登录</el-button>
              </div>
            </el-form-item>
          </el-form>
        </transition>
        <transition name="slide-fade" mode="out-in">
          <el-form
            v-if="!isLogin"
            key="register-form"
            ref="registerForm"
            :model="registerForm"
            :rules="registerRules"
            label-width="0"
          >
            <el-form-item prop="email">
              <el-input v-model="registerForm.email" placeholder="邮箱" :prefix-icon="emailIcon">
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="registerForm.password"
                type="password"
                placeholder="密码"
                :prefix-icon="passwordIcon"
                show-password
              >
              </el-input>
            </el-form-item>

            <el-form-item prop="code">
              <div class="verify-code" :gutter="20">
                <el-input
                  v-model="registerForm.code"
                  placeholder="验证码"
                  :prefix-icon="verificationCodeIcon"
                >
                </el-input>
                <el-button type="primary" @click="getVerificationCode" :disabled="disabled"
                  >获取验证码{{ countDown ? `(${countDown})` : "" }}</el-button
                >
              </div>
            </el-form-item>
            <el-form-item>
              <div class="auth-btn">
                <el-button type="primary" @click="submitRegisterForm">注册</el-button>
              </div>
            </el-form-item>
          </el-form>
        </transition>
        <div class="switch-container">
          <span>{{ isLogin ? "没有账号？" : "已有账号？" }}</span>
          <el-button type="text" @click="toggleForm">{{ isLogin ? "注册" : "登录" }}</el-button>
        </div>
        <el-divider class="more_login_divider">其它登录方式</el-divider>
        <OtherLogin></OtherLogin>
      </div>
    </el-card>
  </div>
</template>

<script>
import Api from "./api.js";
import { mapActions } from "vuex";
import OtherLogin from "./other_login.vue";
import APageLogo from "./a_page_logo.vue";

export default {
  name: "Login",
  components: {
    OtherLogin,
    APageLogo,
  },
  data() {
    return {
      usernameIcon: "el-icon-user",
      passwordIcon: "el-icon-lock",
      emailIcon: "el-icon-message",
      verificationCodeIcon: "el-icon-key",
      isLogin: true,
      loginForm: {
        email: "",
        password: "",
      },
      registerForm: {
        email: "",
        password: "",
        code: "",
      },
      disabled: false,
      timer: null,
      countDown: "",
      loginRules: {
        email: [
          { required: true, message: "请输入账号", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
      registerRules: {
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          { type: "email", message: "请输入正确的邮箱地址", trigger: ["blur", "change"] },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    ...mapActions("user", ["setUserInfo", "setToken", "getUserInfo"]),
    toggleForm() {
      this.isLogin = !this.isLogin;
      this.resetForm();
    },
    resetForm() {
      if (this.isLogin) {
        this.$refs.registerForm.resetFields();
      } else {
        this.$refs.loginForm.resetFields();
      }
    },
    async getVerificationCode() {
      this.$refs.registerForm.validateField("email", async (msg) => {
        if (!msg) {
          try {
            const { email } = this.registerForm;
            this.disabled = true;
            await Api.getCaptcha({ email });
            this.countDown = 30;
            this.interval();
            this.$message.success("验证码已发送！");
          } catch (e) {
            this.countDown = 0;
            this.disabled = false;
          }
        }
      });
    },
    interval() {
      this.timer = setInterval(() => {
        this.countDown -= 1;
        if (this.countDown === 0) {
          clearInterval(this.timer);
          this.disabled = false;
        }
      }, 1000);
    },
    async setUserInfoByData(userInfo) {
      await this.setToken(`Bearer ${userInfo.token}`);
      await this.setUserInfo(userInfo);
      this.$eventBus.$emit("tokenChange", `Bearer ${userInfo.token}`);
      window.localStorage.setItem(
        "NEW_TOKEN",
        JSON.stringify({
          access_token: userInfo.token,
          token_type: "Bearer",
        }),
      );
    },
    async submitLoginForm() {
      try {
        await this.$refs.loginForm.validate();
        const { email, password } = this.loginForm;
        const { data: userInfo } = await Api.oauth({ email, password });
        await this.setUserInfoByData(userInfo);
        this.$message.success("成功登录！");
        this.$router.push("/project");
      } catch (e) {
        return false;
      }
    },
    async submitRegisterForm() {
      try {
        await this.$refs.registerForm.validate();
        const { email, code, password } = this.registerForm;
        const lastIndex = email.lastIndexOf("@");
        const username = email.slice(0, lastIndex);
        const { data: userInfo } = await Api.registry({ email, code, username, password });
        await this.setUserInfoByData(userInfo);
        this.$message.success("注册成功！");
        this.$router.push("/project");
      } catch (e) {}
    },
  },
};
</script>

<style scoped lang="scss">
.auth-container {
  font-family: "Alibaba PuHuiTi", "Arial", sans-serif;
  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #fafafa;
  background-size: cover;
  background-position: center;
  ::v-deep .el-button.el-button--primary {
    background-color: #3566fc;
  }
  ::v-deep .el-button {
    font-family: "Alibaba PuHuiTi", "Arial", sans-serif;
  }
  .auth-card {
    width: 400px;
    padding: 20px;
    padding-top: 0px;
    box-shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.13), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 12px 24px 16px rgba(0, 0, 0, 0.04);
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    position: relative;

    .card-container {
      position: relative;
      padding: 0 10px;
      .more_login_divider {
        margin-top: 50px;
      }
    }

    .verify-code {
      display: flex;
      gap: 16px;
    }
  }
  .auth-btn {
    width: 100%;

    ::v-deep .el-button {
      width: 100%;
    }
  }

  .logo-wrap {
    display: flex;
    align-items: center;
    padding: 20px;
    padding-top: 30px;
    margin-bottom: 20px;
    justify-content: center;
    .auth-card-logo {
      width: 52px;
      height: 52px;
    }

    .logo-text {
      font-size: 30px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 8px;
      font-weight: 700;
      color: #262626;
    }
  }
}

.auth-title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.switch-container {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-fade-leave-active {
  position: absolute;
}
</style>
