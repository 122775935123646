/** @format */

'use strict';

import ajax from '@utils/request.js'

const user = {
  /**
   * 获取用户信息
   * @param {*} params
   * @returns
   */
  userinfo: (params) => {
    let data = ajax.post(`/user/userinfo`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 退出
  logout: (params) => {
    let data = ajax.post(`/user/logout`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 判断用户权限
  checkPermissionBySlug (params) {
    let data = ajax.post(`/user/checkPermissionBySlug`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
* 获取研发信息中心人员
*/
  queryEmpUserList: (params) => {
    let data = ajax.post(`/oa/queryEmpUserList`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
 * 获取所有消息类型
 */
  getAllMessageType: (params) => {
    let data = ajax.get(`/message/getAllMessageType`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 获取消息列表
  getMessageList: (params) => {
    let data = ajax.get(`/message/getMessageList`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 获取消息设置列表
  getMessageSettingList: (params) => {
    let data = ajax.get(`/message/getMessageSettingList`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 编辑message设置
  editMessageSetting: (params) => {
    let data = ajax.post(`/message/editMessageSetting`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 删除message设置
  delMessageSetting: (params) => {
    let data = ajax.post(`/message/delMessageSetting`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 同步项目成员消息设置数据
  syncMemberMessageSetting: (params) => {
    let data = ajax.post(`/message/syncMemberMessageSetting`, params).then((response) => {
      return response.data;
    });
    return data;
  },
};

export default user;
