<template>
  <div class="layout">
    <el-container>
      <!-- 侧边栏 -->
      <el-aside id="domSideBar1">
        <el-scrollbar>
          <Aside />
        </el-scrollbar>
      </el-aside>
      <el-container>
        <!-- 头部 -->
        <el-header>
          <div class="main-container" style="width:100%;">
            <div class="fixed-header">
              <Header @change_ifram="changeIframe"></Header>
            </div>
          </div>
        </el-header>
        <!-- 内容主体 -->
        <el-main>
          <el-scrollbar>
            <PageMain />
          </el-scrollbar>
        </el-main>
      </el-container>
    </el-container>
    <div class="bigpan-pannel" v-if="isShowBigPan">
      <iframe id="iframe-bigpan" src="https://bigpan.xsyxsc.com" frameborder="0" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
import Aside from './components/Aside/index'
import Header from './components/Header'
import PageMain from './components/PageMain'

// import TagsView from './components/TagsView'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['token', 'userInfo'])
  },
  data () {
    return {
      isShowBigPan: false,
    }
  },
  provide: function () {
    return { bigpan: () => this.isShowBigPan }
  },
  mounted () {
    this.$eventBus.$on('changebigpan', (data) => {
      this.isShowBigPan = false
    })
  },
  watch: {
    isShowBigPan: {
      immediate: true,
      async handler (n) {
        if (n) {
          const that = this
          await this.$nextTick()
          const params = {
            isMedusaIframe: true,
          }
          this.iframe = document.getElementById('iframe-bigpan')
          this.iframe.contentWindow.postMessage(params, '*')
          this.iframe.onload = function () {
            console.log('iframe.contentWindow.postMessage:', params)
            that.iframe.contentWindow.postMessage(params, '*')
          }
        }
      }
    }
  },
  components: {
    Aside,
    Header,
    PageMain,
    Header
  },
  methods: {
    changeIframe (data) {
      this.isShowBigPan = data
    }
  }
}
</script>

<style lang="scss" scoped>
.bigpan-pannel {
  position: absolute;
  width: 100vw;
  height: calc(100vh - 64px);
  top: 64px;
  left: 0px;
  z-index: 100001;
  box-sizing: border-box;
  overflow: auto;
}
</style>
