import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import { startMicroApps } from "./plugins/micro-app";
// import { kyLinSSORun } from "./api/login";
import elTableInfiniteScroll from "el-table-infinite-scroll";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/style/elementTheme/element-#165dff/theme/fonts/element-icons.ttf";
import "./assets/style/elementTheme/element-#165dff/theme/fonts/element-icons.woff";
import "./assets/style/elementTheme/element-#165dff/theme/index.css";
import "@/assets/style/index.scss";
import store from "./store/index";
import EventBus from "./plugins/eventBus";
Vue.prototype.kylin = {};
Vue.prototype.$eventBus = EventBus;
Vue.use(ElementUI);
Vue.use(elTableInfiniteScroll);

// import { registerMicroApps, start } from 'qiankun' //新增部分，导入qiankun中的两个方法
// const apps = [{
//     name: 'medusa-web-xps', //子应用的名称
//     entry: 'http://localhost:7101', //子应用的域名
//     container: '#vue', //承载子应用的容器，在上面App.vue中定义
//     activeRule: '/vue', // 被激活的子应用的路由
// }]
// registerMicroApps(apps); //注册子应用
// start(); //启动qiankun

// kyLinSSORun(() => {
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
startMicroApps();
// })
