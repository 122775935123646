import DynamicStore from "@lmio/dynamic-store";
import userApi from "../../views/home/api";

const state = {
  token: "",
  userInfo: {},
};

const actions = {
  setUserInfo({ commit }, userInfo) {
    console.log("userInfo: ", userInfo);
    commit("userInfo", userInfo);
  },
  setToken({ commit }, token) {
    commit("token", token);
  },

  async getUserInfo({ commit }, userInfo = {}) {
    let _res = await userApi.userinfo();
    if (_res.code == "200") {
      commit("userInfo", Object.assign(userInfo, _res.data));
    }
  },
  // 获取角色权限信息
  getUserRoles({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        let roles = ["Permission", "PageAdmin"];
        commit("SET_ROLES", roles);
        resolve(roles);
      } catch (err) {
        reject(err);
      }
    });
  },

  // 登出
  loginOut({ commit }) {
    return new Promise((resolve, reject) => {
      try {
        commit("token", "");
        commit("userInfo", "");
        window.localStorage.removeItem("NEW_TOKEN");
        window.localStorage.removeItem("NEW_USERINFO");
        resolve(true);
      } catch (err) {
        reject(err);
      }
    });
  },
};

// tips 需要提前执行 先于state初始化
const userDS = new DynamicStore(state, {
  actions,
  persistence: true,
  mutationController: ["set", "persistence"],
});

export default userDS.exportModule();
