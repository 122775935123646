import { Loading } from "element-ui";
let loadingInstance = null;
let timer = null
export function baseLoading() {
  return Loading.service({
    lock: true,
    spinner: "cyc-loading-icon",
    fullscreen: true
  });
}
export function loading(status) {
  if (status) {
    if (loadingInstance) return;
    loadingInstance = Loading.service({
      lock: true,
      spinner: "cyc-loading-icon",
      fullscreen: true
    });
  } else {
    if (!loadingInstance) return;
    clearTimeout(timer)
    timer = setTimeout(()=>{
      loadingInstance.close();
      loadingInstance = null;
    },200)
  }
}