<template>
  <div class="page-main">
    <transition name="fade-page" mode="out-in">
      <!-- <keep-alive>
        <router-view v-if="!$route.meta.noCache" />
      </keep-alive> -->
      <div id="appContainer">
      </div>
    </transition>
  </div>
</template>

<script>

export default {
}
</script>

<style lang="scss" scoped>
.page-main {
  height: calc(100vh - 64px);
  overflow: hidden;
}
</style>
