<template>
  <div class="new-offline-package">
    <el-form size="small" :model="formData" ref="dialogForm" class="filter-area" @submit.native.prevent label-width="150px" label-position="top" :rules="rules">
      <div class="flex-container">
        <div class="flex-items">
          <el-form-item label="离线包ID" prop="name">
            <el-input v-model="formData.name" placeholder="离线包在melons平台的标识，建议格式：xxx-xxx-xxx"></el-input>
          </el-form-item>

          <el-form-item label="GitLab项目名称" prop="projectName">
            <el-input v-model="formData.projectName" placeholder="请输入对应的项目名，一般使用Gitalb项目名（英文）"></el-input>
          </el-form-item>

          <el-form-item label="入口URL" prop="enterUrl">
            <el-input v-model="formData.enterUrl" placeholder="入口URL"></el-input>
          </el-form-item>

          <el-form-item label="离线包描述" prop="desc">
            <el-input v-model="formData.desc" placeholder="离线包描述" type="textarea" :rows="7"></el-input>
          </el-form-item>
        </div>

        <div class="flex-items">
          <el-form-item label="GITLAB PROJECT ID" prop="gitProjectId">
            <el-input v-model="formData.gitProjectId" placeholder="GITLAB PROJECT ID"></el-input>
          </el-form-item>

          <el-form-item label="GITLAB URL" prop="gitUrl">
            <el-input v-model="formData.gitUrl" placeholder="GITLAB URL"></el-input>
          </el-form-item>

          <el-form-item label="是否推送" prop="enableWxPush">
            <el-switch v-model="formData.enableWxPush"> </el-switch>
          </el-form-item>

          <el-form-item label="企业微信WebHook" prop="wxWebhook">
            <el-input v-model="formData.wxWebhook" placeholder="企业微信机器人WebHook"></el-input>
          </el-form-item>

          <el-form-item label="开启多环境" prop="isEnableEnv">
            <el-switch v-model="formData.isEnableEnv"> </el-switch>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>

</template>
<script>
import api from '../api';
export default {
  name: "newOfflinePackageForm",
  props: {
    projectType: {
      type: String,
      default: 'web'
    },
    slug: {
      type: String,
      default: ''
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.submitLoading;
      },
      set (val) {
        this.$emit('update:submitLoading', val);
      }
    }
  },
  data () {
    return {
      formData: {
        name: "", // 离线包标识
        projectName: "", // 离线包名称
        enterUrl: "",
        gitProjectId: "",
        gitUrl: "",
        wxWebhook: "",
        enableWxPush: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入离线包标识，建议格式：xxx-xxx-xxx",
          },
        ],
        projectName: [
          { required: true, message: "请输入离线包名称，建议使用中文" },
        ],
        enterUrl: [{ required: true, message: "请输入入口URL" }],
        gitProjectId: [{ required: true, message: "请输入GITLAB PROJECT ID" }],
        gitUrl: [{ required: true, message: "请输入GITLAB URL" }],
      },
    };
  },
  methods: {
    submitForm () {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          try {
            this.isLoading = true;
            const params = {
              ...this.formData,
              slug: this.slug,
              project_type: this.projectType,
            };
            let res = await api.createNewPackage(params);
            if (res.code === 200) {
              this.$message.success(res.message);
              this.$router.replace(`/project?pid=${res.data.id}&type=packages_v2`);
            }
          } catch (error) {
            console.log('error: ', error);
          } finally {
            this.isLoading = false;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-offline-package {
  margin: 5px 0 5px 25px;
  max-height: calc(100% - 420px);
  ::v-deep .el-input {
    width: 260px;
  }

  ::v-deep .el-input-number {
    input {
      text-align: left;
    }
  }
  .flex-container {
    display: flex;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    .flex-items {
      flex: 1;
      &:last-child {
        margin-left: 25px;
      }
    }
  }
}
</style>

