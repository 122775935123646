<template>
  <div class="layout-top">
    <div class="logo-icon" v-if="!($route.path && $route.path.startsWith('/app-'))">
      <img
        src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/Group 1@3x.png"
        class="sidebar-logo"
      />
      <h1 class="sidebar-title">{{ title }}</h1>
    </div>
    <div id="domColapse" class="open-icon" v-if="!noShowPath.includes($route.path)">
      <i v-if="opened" class="el-icon-s-fold font24" @click="handleClose"></i>
      <i v-else class="el-icon-s-unfold font24" @click="handleOpen"></i>
    </div>
    <div class="project-change" @click="changeProject">
      <img
        class="project-logo"
        :src="
          currentProject.logo ||
          'https://front-xps-cdn.xsyx.xyz/custom/day360/2022/07/26/1568956799.png'
        "
        alt="error"
      />
      {{ currentProject.project_name || "请选择项目" }}
      <i class="el-icon-sort change90"></i>
      <div
        v-if="currentProject.relations_slug"
        class="ralative-project"
        @click.stop="changeRalativeProject"
      >
        {{ relationProjectName }}
      </div>
    </div>
    <div class="header-r">
      <!-- <div v-if="$route.path && $route.path.startsWith('/app-')" @click="showBigPan" class="bigpan-icon" title="比个盘"></div> -->
      <Notice
        v-if="currentProject && currentProject.project_type === 'web'"
        title="消息通知"
      ></Notice>
      <!-- <i class="el-icon-s-order document-icon font24" @click="openNewPage" title="文档中心"></i> -->
      <!-- <i class="el-icon-picture picture-icon font24" title="我的文件" @click="goto('/app-xps#/myFiles')"></i> -->
      <UserDropdown></UserDropdown>
    </div>
  </div>
</template>
<script>
import UserDropdown from "@/components/UserDropdown";
import Notice from "@/components/Notice";
import { mapGetters, mapActions } from "vuex";
import tool from "@/utils/tool.js";
import merge from "webpack-merge";
export default {
  components: {
    UserDropdown,
    Notice,
  },
  computed: {
    ...mapGetters(["currentProject", "opened", "groupList"]),
    proList() {
      const proList = [];
      this.groupList.forEach((item) => {
        if (item.type === "group") {
          proList.push(...item.projects);
        }
      });
      return proList;
    },
    relationProject() {
      return this.proList.find((item) => item.slug === this.currentProject.relations_slug) || {};
    },
    relationProjectName() {
      return (
        (this.relationProject && this.relationProject.project_name) ||
        this.currentProject.relations_slug
      );
    },
  },
  data() {
    return {
      noShowPath: ["/project", "/newProject"],
      title: "A·PAGE前端工作台",
      logo: "https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/Group 1@3x.png",
      project: {},
    };
  },
  inject: ["bigpan"],
  methods: {
    ...mapActions("project", ["setCurrentProject"]),
    goto(url) {
      this.$router.push(url);
    },
    // 切换关联项目
    changeRalativeProject() {
      if (this.proList.length) {
        this.$confirm(
          `点击【确定按钮】切换关联的【${
            this.currentProject.plantform_type === "Pc" ? "移动端" : "PC端"
          }】项目【${this.relationProjectName}】`,
          "操作提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            callback: (action) => {
              if (action === "confirm") {
                if (this.relationProject) {
                  // 设置当前切换的关联项目  因为在setCurrentProject方法执行后 this.relationProject 会动态改变  所以需要用一个变量接收当前的关联项目
                  const relationProject = this.relationProject;
                  // 保留老项目名称 做提示
                  const oldName = `【${this.relationProjectName}】`;
                  this.setCurrentProject(relationProject);
                  this.$notify({
                    title: "项目提示",
                    dangerouslyUseHTMLString: true,
                    message: `<strong>您已经成功切换到<i style="color:#165DFF;">${oldName}</i>项目</strong>`,
                    type: "success",
                  });
                  // 如果在代码管理页面 增加pid参数
                  const path = `${this.$route.path}${this.$route.hash.split("?")[0]}`;
                  if (path === "/app-main#/") {
                    this.$router.push({
                      path: "/app-main#/",
                      query: merge(this.$route.query, { pid: relationProject.id }),
                    });
                  }
                } else {
                  this.$notify({
                    title: "项目提示",
                    message: `<strong>请检查关联项目的权限，3s后将跳转到<i style="color:#165DFF;">【项目列表页面】</i></strong>`,
                    type: "warning",
                  });
                  setTimeout(() => {
                    this.$router.push("/project");
                  }, 3000);
                }
              }
            },
          },
        );
      } else {
        this.$confirm("没有检测到项目列表，点击【确定按钮】跳转到【项目列表页面】！", "操作提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          callback: (action) => {
            if (action === "confirm") {
              this.$router.push("/project");
            }
          },
        });
      }
    },
    changeProject() {
      if (location.pathname === "/project") {
        let queryObj = tool.getAllQueryParams();
        if (queryObj.pid) {
          this.$router.replace({
            path: "/app-main#/",
            query: merge(this.$route.query, { pid: queryObj.pid }),
          });
        } else {
          this.$router.go(-1);
        }
      }
      if (location.pathname === "/newProject") {
        return;
      }
      this.$router.push("/project");
    },
    goPage(path) {
      this.$router.push(path);
    },
    openNewPage() {
      window.open("https://medusa-main.xsyxsc.com/docsgroup/efficacy/", "__blank");
    },
    // 侧边栏展开
    handleOpen() {
      this.$store.commit("app/opened", true);
    },
    // 侧边栏关闭
    handleClose() {
      this.$store.commit("app/opened", false);
    },
    showBigPan() {
      this.$emit("change_ifram", !this.bigpan());
    },
  },
};
</script>
<style scoped lang="scss">
.layout-top {
  height: 64px;
  background: #f2f3f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0px;
  color: #4e5969;
  font-weight: 300;
  .logo-icon {
    display: flex;
    width: 220px;
    height: 64px;
    box-sizing: border-box;
    align-items: center;
    margin-left: 12px;
    img {
      width: 36px;
      height: 36px;
      vertical-align: middle;
      margin-right: 12px;
    }
    h1 {
      display: inline-block;
      color: #4e5969;
      margin: 0;
      font-weight: 600;
      font-style: normal;
      line-height: 50px;
      font-size: 14px;
      vertical-align: middle;
      font-size: 16px;
      font-family: "OPPOSans";
      text-transform: uppercase;
    }
  }
  .font24 {
    font-size: 24px;
  }
  .picture-icon {
    margin: 0 5px 0 12px;
    cursor: pointer;
  }
  .document-icon {
    margin-left: 5px;
    cursor: pointer;
  }
  .project-change {
    cursor: pointer;
    display: flex;
    align-items: center;
    .project-logo {
      width: 40px;
      height: 40px;
      margin-right: 5px;
      object-fit: contain;
    }
    .ralative-project {
      padding: 0 10px;
      color: #157dff;
    }
    .change90 {
      margin-left: 10px;
      transform: rotate(90deg);
    }
  }
  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: 100%;
    transition: width 0.28s;
  }
  .bigpan-icon {
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border-radius: 50%;
    background-image: url("https://front-xps-cdn.xsyx.xyz/custom/bigpan/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation-name: scaleDraw; /*关键帧名称*/
    -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
    -webkit-animation-iteration-count: infinite; /*动画播放的次数*/
    -webkit-animation-duration: 5s; /*动画所花费的时间*/
    cursor: pointer;
  }
}
/*css部分*/
@keyframes scaleDraw {
  /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
  0% {
    transform: scale(0.8); /*开始为原始大小*/
  }
  25% {
    transform: scale(1.1); /*放大1.1倍*/
  }
  50% {
    transform: scale(0.8);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(0.8);
  }
}
</style>
