import Vue from 'vue'
export default new Vue()
// export default class EventEmitter {
//     static eventCenter = {}
//     constructor (name) {
//       this.name = name
//       this.releaseEvents()
//     }
  
//     dispatchToApp (app, eventName, ...args) {
//       if (!app) throw new Error('app 名称必须指定')
//       if (!eventName) throw new Error('app 事件必须指定')
//       const appQueue = this.constructor.eventCenter[app]
//       const queue = appQueue || appQueue[eventName]
//       if (queue) {
//         queue.forEach(fn => {
//           bus(fn) && fn.call(fn, {
//             from: this.name,
//             data: args
//           })
//         })
//       }
//     }
  
//     addAppListener (eventName, callback) {
//       if (!eventName || !bus(callback)) return
//       if (!this.listener[eventName]) this.listener[eventName] = []
//       this.listener[eventName].push(callback)
//     }
  
//     removeAppListener (eventName, callback) {
//       if (!eventName) return
//       const queue = this.listener[eventName]
//       if (!queue) return
//       if (callback) {
//         this.listener[eventName] = queue.filter(fn => fn !== callback)
//       } else {
//         this.listener[eventName] = []
//       }
//     }
  
//     releaseEvents () {
//       this.listener = {}
//       this.constructor.eventCenter[this.name] = this.listener
//     }
//   }