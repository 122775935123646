/** @format */

const UAT_CONFIGURE = {
  micoSystemList: [
    {
      platformName: "A·PAGE",
      microName: "main",
      activeRule: "/app-main",
      entry: "http://uat-medusa-main.cs-test.xsyxsc.cn",
    },
    {
      platformName: "工作台",
      microName: "xps",
      activeRule: "/app-xps",
      entry: "https://medusa-instance-in.xsyxsc.com/uat/xps",
    },
    {
      platformName: "配置中心",
      microName: "configuration-center",
      activeRule: "/app-configuration-center",
      entry: "https://medusa-instance-in.xsyxsc.com/dev/configuration",
    },
    {
      platformName: "地宝代码扫描",
      microName: "micro-name",
      activeRule: "/app-micro-name",
      entry: "https://medusa-instance-in.xsyxsc.com/uat/deebot-web#/",
    },
    {
      platformName: "raven自动发布平台",
      microName: "raven-client",
      activeRule: "/app-raven-client",
      entry: "http://10.255.31.175:3001",
    },
    {
      platformName: "Melons移动应用托管平台",
      microName: "melons",
      activeRule: "/app-melons",
      entry: "http://melons.xsyxsc.cn",
    },
  ],
};
export default UAT_CONFIGURE;
