<template>
  <div class="sidebar" :class="{ sidebarCloseWidth: !opened }">
    <div>
      <logo :collapse="!opened" />
    </div>
    <el-menu :default-active="activeMenu" :collapse="!opened" :collapse-transition="false" background-color="#F2F3F5" text-color="#4E5969" active-text-color="#165DFF" router>
      <sidebar-item v-for="route in menusList" :key="route.path" :item="route" />
    </el-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import menu from '@/config/index';
import SidebarItem from './SidebarItem';
import Logo from './Logo.vue';
export default {
  components: {
    SidebarItem, Logo
  },
  computed: {
    ...mapGetters(['routes', 'addRoutes', 'opened']),
    ...mapGetters('project', ['currentProject']),
    // 默认激活项
    activeMenu () {
      const { path, hash } = this.$route
      const activeMenu = path + hash.split('?')[0];
      return activeMenu
    },
    menusList () {
      return menu.menusList && menu.menusList.filter(item => item.type.includes(this.currentProject.project_type) || ((this.currentProject.project_type === 'app' && this.currentProject.isHotfix) ? item.type.includes('patch_app') : false)) || []
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  padding: 0 15px 0 5px;
  .el-menu-item {
    margin: 2px 0;
    padding-left: 10px !important;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
    &:hover {
      background-color: white !important;
      border-radius: 8px;
      color: #165dff !important;
      i {
        color: #165dff;
      }
    }
    &.is-active {
      border-radius: 8px;
      background-color: white !important;
    }
  }
  .el-submenu__title {
    padding-left: 10px !important;
    padding: 0 15px;
    height: 48px;
    line-height: 48px;
    &:hover {
      background-color: white !important;
      border-radius: 5px;
      color: #165dff !important;
      i {
        color: #165dff;
      }
    }
  }
  .el-submenu {
    .el-menu-item {
      padding-left: 40px !important;
      padding: 0px;
      height: 48px;
      line-height: 48px;
      min-width: 140px;
      &:hover {
        background-color: white !important;
        border-radius: 5px;
        color: #165dff !important;
      }
    }
    .el-submenu__title {
      height: 48px;
      line-height: 48px;
      &:hover {
        background-color: white !important;
        border-radius: 5px;
        color: #165dff !important;
      }
    }
  }
  .el-menu--collapse {
    width: 48px;
    .el-tooltip {
      padding: 0 10px !important;
    }
  }
}
.el-menu--vertical {
  .el-menu--popup-right-start {
    width: 168px;
    background-color: #ffffff !important;
    border-radius: 8px;
    min-width: 100px;
    padding: 5px 5px;
    box-sizing: border-box;
    margin-left: 15px;
    margin-top: 5px;
    position: relative;
    &::before {
      // content: url(https://front-xps-cdn.xsyx.xyz/custom/medusaResouce/Polygon.png);
      // position: absolute;
      // background-color: red;
      // // width: 10px;
      // // height: 10px;
      // width: 0;    height: 0;    border: 10px solid;
      // border-color: transparent transparent red;
      // left:-10px;
      // top:15px;
    }
    .el-menu-item {
      background-color: #ffffff !important;
      font-weight: 500;
      font-size: 14px;
      line-height: 42px;
      margin: 2px 0;
      width: 160px;
      height: 42px;
      border-radius: 8px;
      &.is-active {
        background: #e8f3ff !important;
      }
      &:hover {
        background: #f2f3f5 !important;
      }
    }
  }
}
</style>
