export default {
  data () {
    return {
      fullscreen: false,
      dialog: {
        title: '', // 弹窗标题
        component: null, // 弹窗内嵌组件
        visible: false, // 是否显示
        width: '50%', // 弹窗宽度
        top: '15vh', // 弹窗高度
        className: 'default-dialog' // 弹窗类名
        // todo 更多设置
      },
    }
  },
  methods: {
    /**
     * 弹窗方法 todo 更多配置参数 动态参数处理
     */
    setDialog (option) {
      // 默认配置
      const defaultOption = {
        visible: false,
        title: '',
        component: null,
        width: '50%',
        top: '15vh',
        className: 'default-dialog'
      };
      // 合并配置
      let asOption = Object.assign(defaultOption, option);
      if (asOption.visible) {
        // 打开弹出层
        this.dialog = asOption;
      } else {
        // 关闭弹出层
        // 先控制弹出层隐藏动画
        this.dialog = { ...this.dialog, visible: asOption.visible };
        // 动画延迟之后修改其他配置 减少弹出层闪烁
        setTimeout(() => {
          this.dialog = { ...this.dialog, ...asOption };
        }, 300)
      }
    }
  }
}
