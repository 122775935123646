<template>
<div class="medusa-base-dialog"> <el-dialog  :visible.sync="visibleDialog" :center="dialogOpting.layout" v-bind="$attrs" v-on="$listeners" :title="dialogOpting.title"
   :before-close="handleClose"
   :width="dialogOpting.width || '50%'">
    <!--内容区域的默认插槽-->
    <slot />
    <!--使用弹框的footer插槽添加按钮-->
    <template #footer>
      <!--对外继续暴露footer插槽，有个别弹框按钮需要自定义-->
      <slot name="footer" v-if="!dialogOpting.hiddenFoot">
        <!--将取消与确定按钮集成到内部-->
        <el-row slot="footer" type="flex" :justify="dialogOpting.footLayout || dialogOpting.layout">
          <el-col :span="6">
            <el-button type="primary" size="small" @click="$_handleConfirm"
              >确定</el-button
            >
            <el-button size="small" @click="$_handleCancel">取消</el-button>
          </el-col>
        </el-row>
      </slot>
    </template>
  </el-dialog></div>
 
</template>
<script>
export default {
  // 默认情况下父作用域的不被认作 props 的 attribute 绑定 (attribute bindings)
  // 将会“回退”且作为普通的 HTML attribute 应用在子组件的根元素上。
  // 通过设置 inheritAttrs 到 false，这些默认行为将会被去掉
  inheritAttrs: false,
  props: {
    // 对外暴露visible属性，用于显示隐藏弹框
    visible: {
      type: Boolean,
      default: false,
    },
    dialogOpting: {
      type: Object,
      default: false,
    }
  },
  computed: {
    // 通过计算属性，对.sync进行转换，外部也可以直接使用visible.sync
    visibleDialog: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    // 对外抛出cancel事件
    $_handleCancel() {
      this.$emit("cancel");
    },
    // 对外抛出 confirm事件
    $_handleConfirm() {
      this.$emit("confirm");
    },
  },
};
</script>
<style lang="scss">

.medusa-base-dialog{
    .el-dialog{
        border-radius: 8px;
    }
    .el-dialog__title{
        font-size: 16px;
        color: #1D2129;
        font-weight: 400;

    }
        .el-dialog__header{
            border-bottom: 1px solid #E5E6EB;
    }
}



</style>