// import { asyncRoutes, currencyRoutes } from '@/router'
import DynamicStore from '@lmio/dynamic-store';

// 遍历asyncRoutes动态路由
function forSearchArr(route, roles) {
  let arrNew = []
  for (let item of route) {
    let itemNew = {
      ...item
    } // 解决浅拷贝共享同一内存地址
    if (roles.includes(itemNew.name)) {
      if (itemNew.children) {
        itemNew.children = forSearchArr(itemNew.children, roles)
      }
      arrNew.push(itemNew)
    }
  }
  return arrNew
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES(state, payload) {
    // state.routes = [...currencyRoutes, ...payload]
    state.addRoutes = payload
  }
}

const actions = {
  getAsyncRoutes({ commit, rootGetters }, roles) {
    // return new Promise(resolve => {
    //   let routes = []
    //   if (rootGetters.userName === 'admin') {
    //     routes = asyncRoutes || ''
    //   } else {
    //     routes = forSearchArr(asyncRoutes, roles)
    //   }
    //   commit('SET_ROUTES', routes)
    //   resolve(routes)
    // })
  }
}

// tips 需要提前执行 先于state初始化
const permissionDS = new DynamicStore(state, {
  actions,
  persistence: true,
  mutationController: ['set', 'persistence']
});

export default permissionDS.exportModule();
