<template>
  <div class="notice-container">
    <el-popover popper-class="notice-message-list" placement="left-start" width="400" trigger="click" :visible-arrow="false" @show="showPopover" @hide="hidePopover">
      <div class="content-box">
        <div class="title-container">
          <h3 class="title">消息通知
            <i class="el-icon-more more-icon" @click="showMessageSettings"></i>
          </h3>
          <div class="tab-container">
            <div class="tab-item" :class="{'active':activeName==='latestNotice'}" @click="handleClick('latestNotice')">最新消息</div>
            <div class="tab-item" :class="{'active':activeName==='historyNotice'}" @click="handleClick('historyNotice')">历史消息</div>
          </div>
          <div class="scorll-bar" ref="bar"></div>
        </div>
        <div class="notice-list-container" v-infinite-scroll="throttleLoad">
          <template v-if="activeName==='latestNotice'&&latestMessageList.length">
            <el-card class="list-item" :body-style="{padding:'10px 12px'}" v-for="item in latestMessageList" :key="item.id">
              <div class="header">
                <strong class="title">{{item.message_name}}</strong>
                <span class="date">{{dateFormat(item.createdAt)}}</span>
                <em class="item-badge">new</em>
              </div>
              <div class="desc">{{item.message_content}}</div>
            </el-card>
          </template>
          <template v-else-if="activeName==='historyNotice'&&historyMessageList.length">
            <el-card class="list-item" :body-style="{padding:'10px 12px'}" v-for="item in historyMessageList" :key="item.id">
              <div class="header">
                <strong class="title">{{item.message_name}}</strong>
                <span class="date">{{dateFormat(item.createdAt)}}</span>
              </div>
              <div class="desc">{{item.message_content}}</div>
            </el-card>
          </template>
          <template v-else>
            <img class="image-empty" src="https://front-xps-cdn.xsyx.xyz/custom/marvelcode/pro/v1/Empty%20state_money.png" alt="">
          </template>
        </div>
      </div>
      <el-badge :value="latestMessageList.length" :max="99" :hidden="!latestMessageList.length" class="item" slot="reference">
        <i class="bell-icon el-icon-message-solid"></i>
      </el-badge>
    </el-popover>
    <el-dialog :append-to-body='true' :close-on-click-modal="false" :close-on-press-escape="true" :class="dialog.className" :title='dialog.title' :visible='dialog.visible' :width="dialog.width" @close="handleDialogClose" center :top="dialog.top">
      <component :is="dialog.component" @close="handleDialogClose" />
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { dialogMixin } from "@/mixins";
import { throttle } from '@/utils/timerUtil.js';
import List from './list.vue'
import moment from 'moment';
export default {
  mixins: [dialogMixin],
  data () {
    return {
      activeName: 'latestNotice',
      pageConfig: {
        pageNum: 1,
        pageSize: 20
      },
      canLoading: true
    }
  },
  computed: {
    ...mapGetters('message', ['latestMessageList', 'historyMessageList', 'currentMessageTotal']),
    ...mapGetters('project', ['currentProject'])
  },
  watch: {
    currentProject (val) {
      this.$store.commit("message/isSyncMessageSetting", false);
      this.syncMemberMessageSetting({ project_id: val.id, slug: val.slug });
    }
  },
  mounted () {
    this.showPopover();
  },
  methods: {
    ...mapActions('message', ['getMessageList', 'scrollLoadingMessage', 'syncMemberMessageSetting']),
    // 节流 500毫秒调用一次
    throttleLoad () {
      throttle(this.loadingMessageList, 500)
    },
    // 滚动加载
    async loadingMessageList () {
      // 如果还能加载
      if (this.canLoading) {
        // 页数++
        this.pageConfig.pageNum++;
        // 滚动加载消息列表
        await this.scrollLoadingMessage({ ...this.pageConfig, isRefresh: true });
        // 判断当前消息列表返回的数量是否大于分页数量
        if (this.currentMessageTotal >= this.pageConfig.pageSize) {
          this.canLoading = true;
        } else {
          this.canLoading = false;
        }
      }
    },
    // 显示时触发
    showPopover () {
      this.scrollBar();
      this.getMessageList({ isRefresh: true });
    },
    // 隐藏时触发
    hidePopover () {
      // 允许滚动加载
      this.canLoading = true;
      // 重置分页
      this.pageConfig.pageNum = 1;
      // 如果有最新消息列表
      if (this.latestMessageList.length) {
        this.$store.dispatch("message/setLatestReadMessageId", this.latestMessageList[0].id);
      }
      // 更新消息状态
      this.$store.dispatch("message/refreshMessageStatus");
    },
    handleClick (name) {
      this.activeName = name;
      this.scrollBar();
    },
    // 滚动Bar
    scrollBar () {
      this.$nextTick(() => {
        const dom = document.querySelector('.tab-item.active');
        const bar = this.$refs['bar'];
        if (dom && bar) {
          bar.style.left = (dom.offsetLeft - 14) + 'px';
        }
      })
    },
    // 日期格式化
    dateFormat (date) {
      return moment(new Date(date)).format('YYYY-MM-DD HH:mm:ss')
    },
    // 打开弹窗
    showMessageSettings () {
      this.setDialog({
        visible: true,
        component: List,
        top: '10vh',
        title: '消息配置',
        width: '80%'
      })
    },
    // 关闭弹窗
    async handleDialogClose () {
      this.setDialog();
    },
  }
}
</script>

<style lang="scss">
.notice-container {
  display: inline-block;
  margin-right: 6px;
  cursor: pointer;
  .bell-icon {
    font-size: 24px;
  }
}
.notice-message-list {
  padding: 0 !important;

  .content-box {
    .title-container {
      padding: 12px;
      height: 87px;
      border-radius: 6px 6px 0 0;

      background: linear-gradient(
        313.72deg,
        rgb(22, 93, 255) 4.23%,
        rgb(97, 198, 255) 100%
      );
      color: white;
      .title {
        .more-icon {
          margin-top: 5px;
          float: right;
          transform: rotate(90deg);
          cursor: pointer;
        }
      }
    }
    .tab-container {
      display: flex;
      justify-content: space-around;
      .tab-item {
        position: relative;
        padding: 10px;
        color: #eee;
        cursor: pointer;
        &.active {
          color: #fff;
        }
      }
    }
    .scorll-bar {
      position: relative;
      width: 80px;
      height: 2px;
      background-color: #fff;
      transition: left 0.3s;
    }
    .notice-list-container {
      padding: 12px;
      height: 600px;
      background: #f2f3f5;
      border-radius: 0 0 6px 6px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 1px;
      }

      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 6px;
        border: 2px solid transparent;
        background-clip: content-box;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .image-empty {
        width: 100%;
        object-fit: contain;
      }
      .list-item {
        margin-bottom: 10px;
        border-radius: 6px;
        overflow: initial;
        .header {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 5px;
          .item-badge {
            position: absolute;
            right: -20px;
            top: -20px;
            background-color: #f56c6c;
            border-radius: 10px;
            color: #fff;
            display: inline-block;
            font-size: 12px;
            /* height: 18px; */
            /* line-height: 18px; */
            padding: 0 6px;
            text-align: center;
            white-space: nowrap;
            border: 1px solid #fff;
          }
          .title {
            font-size: 16px;
          }
          .date {
            font-size: 12px;
            color: #a2a2a2;
          }
        }
        .desc {
          font-size: 12px;
          color: #a2a2a2;
        }
      }
    }
  }
}
</style>