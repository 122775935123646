<template>
  <div class="sidebar-logo-container" :class="{ collapse: collapse }">
    <transition name="sidebarLogoFade">
      <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
        <img @click="showbigpan" v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 v-else class="sidebar-title">{{ title }}</h1>
      </router-link>
      <router-link v-else key="expand" class="sidebar-logo-link" to="/">
        <img @click="showbigpan" v-if="logo" :src="logo" class="sidebar-logo" />
        <h1 class="sidebar-title">{{ title }}</h1>
      </router-link>
    </transition>
  </div>
</template>

<script>
export default {
  name: "SidebarLogo",
  props: {
    collapse: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "A·PAGE前端工作台",
      logo: "https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/Group 1@3x.png",
    };
  },
  methods: {
    showbigpan() {
      this.$eventBus.$emit("changebigpan", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebarLogoFade-enter-active {
  transition: opacity 1.5s;
}

.sidebarLogoFade-enter,
.sidebarLogoFade-leave-to {
  opacity: 0;
}

.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #f2f3f5;
  text-align: center;
  overflow: hidden;
  &.collapse {
    width: 65px;
    margin-left: -10px;
  }
  & .sidebar-logo-link {
    height: 100%;
    width: 100%;

    & .sidebar-logo {
      width: 36px;
      height: 36px;
      vertical-align: middle;
      margin-right: 12px;
    }

    & .sidebar-title {
      display: inline-block;
      color: #4e5969;
      margin: 0;
      font-weight: 600;
      font-style: normal;
      line-height: 50px;
      font-size: 14px;
      vertical-align: middle;
      font-size: 16px;
      font-family: "OPPOSans";
      text-transform: uppercase;
    }
  }

  &.collapse {
    .sidebar-logo {
      margin-right: 0px;
    }
  }
}
</style>
