import createPersistedState from 'vuex-persistedstate'

/**
 * Vuex持久化储存
 * 根据模块选择储存位置
 */

const appPlugins = createPersistedState({
  storage: window.localStorage,
  reducer(store) {
    return {
      app: store.app,
      user: {
        token: store.user.token,
        userInfo: store.user.userInfo
      },
      tagsView: {
        visitedViews: store.tagsView.visitedViews
      },
      project: {
        currentProject: store.project.currentProject
      }
      // tagsView: {
      //   visitedViews: store.tagsView.visitedViews
      // }
    }
  }
})
const userPlugins = createPersistedState({
  storage: window.sessionStorage,
  reducer() {
    return {}
  }
})

export default [appPlugins, userPlugins]
