/*
 * @Author: yuzc
 * @Date: 2020-10-30 14:55:57
 * @Last Modified by: wang pengfei
 * @Last Modified time: 2022-10-24 16:16:36
 * app 实体 相关的接口
 */

"use strict";

import ajax from '@utils/mRequest.js';

// 如果需要使用mock,打开这个注释,在mock.js中添加相应的服务地址即可
// import data from '../mock/mock.js';

const Mapi = {
  /**
   * 获取 app list
   */
  getList: params => {
    let data = ajax.get(`/api/app/list`, { params }).then(response => {
      return response.data;
    });
    return data;
  },
  /**
   * 根据Id获取 app 详情
   */
  getDetailById: params => {
    let data = ajax.post(`/api/app/detail`, params).then(response => {
      return response.data;
    });
    return data;
  },
  /**
   * 根据appId字段获取 app 详情
   */
  getDetailByAppId: params => {
    let data = ajax.get(`/api/app/detailByAppId`, { params }).then(response => {
      return response.data;
    });
    return data;
  },
  /**
   * 新增一个App
   */
  create: params => {
    let data = ajax.post(`/api/app/create`, params).then(response => {
      return response.data;
    });
    return data;
  },

  /**
   * 更新App信息
   * @param params
   * @returns {Promise<*>}
   */
  updateApp: params => {
    return ajax.post(`/api/app/update`, params).then(response => {
      return response.data;
    });
  },

  /**
   * 查询app是否在棱镜创建了对应关系
   * @param params 
   * @returns {Promise<*>}
   */
  isRegister: params => {
    return ajax.post(`/openApi/customData/getMappingList`, params, { headers: { 'x-eo-tags': "c4e1d9a55f3fbecf903b52066d2883d1" }, baseURL: window._config.ljUrl }).then(response => {
      return response.data;
    })
  },

  /**
   * 在棱镜创建对应关系
   * @param  params 
   * @returns {Promise<*>}
   */
  registerInLJ: params => {
    return ajax.post(`/openApi/customData/createMappingConfig`, params, { headers: { 'x-eo-tags': "c4e1d9a55f3fbecf903b52066d2883d1" }, baseURL: window._config.ljUrl }).then(response => {
      return response.data;
    })
  },

  /**
   * 设置app-package-relations
   * @param params
   * @returns {Promise<*>}
   */
  setPackagesRelations: params => {
    return ajax.post(`/api/app/setPackagesRelations`, params).then(response => {
      return response.data;
    });
  },

  /**
   * 删除App信息
   * 逻辑删除
   * @param params
   * @returns {Promise<*>}
   */
  deleteApp: params => {
    return ajax.post(`/api/app/del`, params).then(response => {
      return response.data;
    });
  },

  /**
   * 获取App下载数据
   * @param params
   * @returns {Promise<*>}
   */
  getAppDownloadData: params => {
    return ajax.post(`/openApi/customData/getCustomEventByPValue`, params, { headers: { 'x-eo-tags': "c4e1d9a55f3fbecf903b52066d2883d1" }, baseURL: window._config.ljUrl }).then(response => {
      return response.data.data;
    });
  },


  /**
   * 向客户端推送获取日志推送
   * @param params
   * @returns {Promise<*>}
   */
  pushFeedback: params => {
    return ajax.post(`/api/app/pushFeedback`, params).then(response => {
      return response.data;
    });
  },


  // 获取非热更新项目
  getNonHotUpdateProjects: params => {
    let data = ajax.get(`/api/app/list`, { params }).then(response => {
      return response.data;
    });
    return data;
  },

};

export default Mapi;
