import ajax from "@utils/request.js";
export default {
  list: (params) => {
    let data = ajax.get("/group/listAllPlatform", params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 收藏项目
  addFavoriteProject: (params) => {
    let data = ajax.get(`/project/addFavoriteProject`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 取消收藏项目
  cancelFavoriteProject: (params) => {
    let data = ajax.get(`/project/cancelFavoriteProject`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 新增项目模板
  newTemplate: (params) => {
    let data = ajax.post(`/project/newTemplate`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 根据模板类型查询模板
  getTemplateByType: (params) => {
    let data = ajax.post(`/project/getTemplateByType`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 获取分组列表
  manageList: (params) => {
    let data = ajax.get("/group/allList", params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 新增项目
   * @param params
   * @returns {Promise<T>}
   */
  newProject: (params) => {
    let data = ajax.post(`/project/new`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 新增分组
   * @param params
   * @returns {Promise<T>}
   */
  newGroup: (params) => {
    let data = ajax.post(`/group/new`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 获取子应用项目
   * @param params
   * @returns {Promise<T>}
   */
  getChildList: (params) => {
    let data = ajax.get("/project/getChildList", params).then((response) => {
      return response.data;
    });
    return data;
  },
  /**
   * 获取项目详情
   */
  projectDetail: (params) => {
    let data = ajax.get(`/project/detail`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 下线项目
  offlineProject(params) {
    let data = ajax.post(`/project/offlineProject`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  getProjectLineList(params) {
    let data = ajax.get(`/xone/getProductionLine`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 获取应用列表
  getApplication(params) {
    let data = ajax.get(`/xone/getApplication`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  getRavenURLList(params) {
    let data = ajax.get(`/raven/getUrlInfo`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  /* 
      Melons相关项目新增
    */
  // 创建app项目
  createApp(params) {
    let data = ajax.post(`/melons/createApp`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 创建热更新项目
  createHotfixApp(params) {
    let data = ajax.post(`/melons/createHotfixApp`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 创建新离线包项目
  createNewPackage(params) {
    let data = ajax.post(`/melons/createNewPackage`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 创建离线包项目
  createPackage(params) {
    let data = ajax.post(`/melons/createPackage`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 创建小程序项目
  createMiniPrograme(params) {
    let data = ajax.post(`/melons/createMiniPrograme`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 根据项目类型和id获取slug
  getSlugByTypeAndId(params) {
    let data = ajax.get(`/melons/getSlugByTypeAndId`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 新增slug对应项目表
  createSlugRelation(params) {
    let data = ajax.post(`/melons/createSlugRelation`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 根据type和id返回info
  getInfoByIdAndType(params) {
    let data = ajax.get(`/melons/getInfoByIdAndType`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  // 校验slug
  checkSlugExist(params) {
    let data = ajax.get(`/melons/checkSlugExist`, params).then((response) => {
      return response.data;
    });
    return data;
  },
  checkNameExist(params) {
    let data = ajax.get(`/project/checkProjectName`, params).then((response) => {
      return response.data;
    });
    return data;
  },
};
