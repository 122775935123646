import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "../layout/";
Vue.use(VueRouter);
import menus from "@/config/index";
import Project from "@views/project";
import Login from "@views/login";
import Home from "@views/home";
import NotFound from "@views/notFound/404.vue";
import NewProject from "@views/project/newProject.vue";
import store from "../store";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

function generateChildRouter() {
  const arr = [];
  menus.menusList.forEach((item) => {
    if (item.children && item.children.length > 0) {
      item.children.forEach((v) => arr.push({ path: v.path, component: Layout }));
    } else {
      arr.push({ path: item.path, component: Layout });
    }
  });
  return arr;
}
// 动态重定向
function redirect(currentProject) {
  let menusList =
    currentProject && JSON.stringify(currentProject) !== "{}"
      ? menus.menusList.filter((item) => item.type.includes(currentProject.project_type))
      : menus.menusList;
  if (menusList[0].children && menusList[0].children.length > 0) {
    return menusList[0].children[0].path;
  } else {
    return menusList[0].path;
  }
}

const routes = [
  {
    path: "/",
    redirect: () => redirect(store.getters.currentProject),
  },
  {
    path: "/",
    name: "Layout",
    component: Layout,
    children: [...generateChildRouter(), { path: "app-*", component: Layout }],
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/project",
    name: "Project",
    component: Project,
  },
  {
    path: "/newProject",
    name: "newProject",
    component: NewProject,
  },
  {
    path: "/notFound",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
