<template>
  <div class="other_login">
    <a class="more_login_a">
      <span class="dingtalk" />
    </a>
    <a class="more_login_a">
      <span class="ali" />
    </a>
  </div>
</template>

<script>
export default {
  name: "other_login",
};
</script>

<style lang="scss" scoped>
.other_login {
  width: 329px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding-right: 30px;
  gap: 60px;
  .more_login_a {
    white-space: nowrap;
    cursor: not-allowed;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #117cee;
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;

    span {
      background-image: url("https://front-xps-cdn.xsyx.xyz/libs/meidusa_new/0.0.1/third_sprite.png");
      display: inline-block;
      width: 42px;
      height: 42px;
      cursor: not-allowed;
      background-repeat: no-repeat;
      background-size: 100%;
      vertical-align: middle;
      &.wechat {
        background-position: 0 -44.5px;
      }

      &.dingtalk {
        background-position: 0 -89.7px;
      }
    }
  }
}
</style>
