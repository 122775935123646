<template>
  <div class="message-setting-list">
    <el-table :data="tableData" style="width: 100%" size="small" max-height="600">
      <el-table-column prop="project" label="项目（slug）" width="180">
        <template v-slot="{row}">
          {{row.project&&`${row.project.project_name}（${row.project.slug}）`}}
        </template>
      </el-table-column>
      <el-table-column prop="message_name" label="消息类型" width="180">
      </el-table-column>
      <el-table-column prop="message_post_type" label="通知类型" width="180">
        <template v-slot="{row}">
          <el-tag v-if="row.message_post_type===1" type="primary">站内通知</el-tag>
          <el-tag v-if="row.message_post_type===2" type="success">企业微信</el-tag>
          <el-tag v-if="row.message_post_type===3" type="warning">两者都通知</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="received_user" label="消息接收者" show-overflow-tooltip min-width="150">
        <template v-slot="{row}">
          <span v-for="item in row.received_user.split(',')" :key="item">
            {{item.split('#')[1]}}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="启用状态">
        <template v-slot="{row}">
          <el-tag v-if="row.status===0" type="success">正常</el-tag>
          <el-tag v-else-if="row.status===1" type="warning">停用</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="操作" min-width="100" fixed="right">
        <template v-slot="{row}">
          <el-button type="text" @click="getEditMessageData(row)">编辑</el-button>
          <el-button class="color-red" type="text" @click="delMessageSetting(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog :append-to-body='true' :close-on-click-modal="false" :close-on-press-escape="true" :class="dialog.className" :title='dialog.title' :visible='dialog.visible' :width="dialog.width" @close="handleDialogClose" center :top="dialog.top">
      <component :editData="editData" :is="dialog.component" @close="handleDialogClose" @getMessageSettingList="getMessageSettingList" />
    </el-dialog>
  </div>
</template>

<script>
import api from '../../views/home/api';
import { mapGetters } from 'vuex';
import { dialogMixin } from "@/mixins";
import Edit from './edit.vue'
export default {
  mixins: [dialogMixin],
  data () {
    return {
      tableData: [],
      editData: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  mounted () {
    this.getMessageSettingList();
  },
  methods: {
    // 获取消息配置
    async getMessageSettingList () {
      const res = await api.getMessageSettingList({ received_user: `${this.userInfo.userCode}#${this.userInfo.realName}` })
      if (res.code === 200) {
        this.tableData = res.data;
      }
    },
    // 检查权限
    async checkPermissionBySlug (slug) {
      const res = await api.checkPermissionBySlug({ slug });
      if (res.code === 200) {
        return (res.data && (res.data.role || (res.data.data && res.data.data.role))) || {};
      } else {
        this.$message.error('权限校验失败！');
        return {};
      }
    },
    // 编辑消息信息
    async getEditMessageData (row) {
      const role = await this.checkPermissionBySlug(row.slug);
      if (role && role.role_code === 'admin') {
        this.showEditMessageSettings(row);
      } else {
        this.$message.warning('您的权限不够！')
      }
    },
    // 删除消息配置
    async delMessageSetting (row) {
      const role = await this.checkPermissionBySlug(row.slug);
      if (role && role.role_code === 'admin') {
        this.$confirm("请确定要删除当前消息配置？", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          const res = await api.delMessageSetting({ id: row.id });
          if (res.code === 200) {
            this.getMessageSettingList();
            this.$message.success('删除成功！');
          }
        }).catch((error) => {
          console.log('error: ', error);
          return this.$message.info('已取消！');;
        });
      } else {
        this.$message.warning('您的权限不够！')
      }
    },
    // 打开弹窗
    showEditMessageSettings (row) {
      this.editData = row;
      this.setDialog({
        visible: true,
        component: Edit,
        top: '15vh',
        title: '编辑配置',
        width: '960px'
      })
    },
    // 关闭弹窗
    async handleDialogClose () {
      this.setDialog();
    },
  }
}
</script>

<style>
</style>