<template>
  <div class="hot-update-form">
    <el-form :model="hotUpdateProjectForm" :rules="rules" ref="form" label-width="150px">
      <div class="flex-container">
        <div class="flex-items">
          <el-form-item label="项目名称" prop="appName">
            <el-select v-model="hotUpdateProjectForm.project" value-key="id" filterable remote reserve-keyword placeholder="请输入项目名称" size="medium" style="width: 300px" :remote-method="getNonHotUpdateProjects" :loading="getNonHotUpdateProjectsLoading" @change="selectNonHotUpdateProject">
              <el-option v-for="project in nonHotUpdateProjects" :key="project.id" :label="project.appName" :value="project">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="AppId">
            <el-input v-model="hotUpdateProjectForm.appId" autocomplete="off" size="medium" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="hotUpdateProjectForm.alias" autocomplete="off" size="medium" style="width: 300px" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="webhook">
            <el-input v-model="hotUpdateProjectForm.wxWebhook" placeholder="请输入企业微信通知webhook地址" autocomplete="off" size="medium" style="width: 300px"></el-input>
          </el-form-item>
        </div>
        <div class="flex-items">
          <el-form-item label="开启变更企微通知">
            <el-switch v-model="hotUpdateProjectForm.enableWxPush">
            </el-switch>
          </el-form-item>
          <el-form-item label="仓库地址" prop="gitUrl">
            <el-input v-model="hotUpdateProjectForm.gitUrl" placeholder="请输入gitlab仓库地址" autocomplete="off" size="medium" style="width: 300px"></el-input>
          </el-form-item>
          <el-form-item label="仓库ID" prop="gitProjectId">
            <el-input v-model.number="hotUpdateProjectForm.gitProjectId" placeholder="请输入gitlab仓库id" autocomplete="off" size="medium" style="width: 300px"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
  </div>

</template>

<script>
import hotUpdateServer from "../mApi";
import api from "../api";
export default {
  name: "hotUpdateForm",
  components: {},
  props: {
    projectType: {
      type: String,
      default: 'web'
    },
    slug: {
      type: String,
      default: ''
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      nonHotUpdateProjects: [], // 非热更新项目
      getNonHotUpdateProjectsLoading: false, // 获取非热更新项目是否加载中
      rules: {
        appName: [{ required: true, message: "请选择项目" }],
        gitUrl: [{ required: true, message: "请填写项目地址" }],
        gitProjectId: [{ required: true, message: "请填写项目id" }, {
          type: 'number', message: '请填写正确的项目id'
        }],
      },
      hotUpdateProjectForm: {
        project: null,
        appName: "",
        appId: "",
        alias: "",
        wxWebhook: "",
        enableWxPush: true,
        gitUrl: "",
        gitProjectId: "",
      },
      loading: false,
    };
  },
  computed: {
    isLoading: {
      get () {
        return this.submitLoading;
      },
      set (val) {
        this.$emit('update:submitLoading', val);
      }
    }
  },
  methods: {
    // 选中某个非热更新项目
    selectNonHotUpdateProject (project) {
      this.hotUpdateProjectForm.appName = project.appName;
      this.hotUpdateProjectForm.appId = project.appId;
      this.hotUpdateProjectForm.id = project.id;
      this.hotUpdateProjectForm.alias = project.alias;
    },
    // 获取非热更新项目
    async getNonHotUpdateProjects (keyword) {
      if (keyword !== "") {
        this.getNonHotUpdateProjectsLoading = true;
        let res = await hotUpdateServer.getNonHotUpdateProjects({
          isHotfix: 0,
          platform: "android",
          key: keyword,
        });
        if (res.code === 200) {
          this.getNonHotUpdateProjectsLoading = false;
          this.nonHotUpdateProjects = res.data.rows;
        }
        return;
      } else {
        this.nonHotUpdateProjects = [];
      }
    },
    // 提交
    submitForm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.addHotUpdateProject();
        }
      });
    },
    // 新增热更新项目
    async addHotUpdateProject () {
      try {
        this.isLoading = true;
        delete this.hotUpdateProjectForm.project;
        const params = {
          ...this.hotUpdateProjectForm,
          slug: this.slug,
          project_type: this.projectType,
        }
        const res = await api.createHotfixApp(params);
        if (res.code === 200) {
          this.$message.success("添加成功！");
          this.$router.replace(`/project?pid=${res.data.id}&type=patch_app`);
        } else {
          this.$message.error("添加失败！");
        }
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .flex-items {
    flex: 1;
    &:last-child {
      margin-left: 25px;
    }
  }
}
</style>
