<template>
  <div class="mini-program-container">
    <el-form :model="filterForm" :rules="rules" ref="filterForm" label-width="100px" size="small">
      <el-form-item prop="wxProjectType" label="架构类型" :required="true">
        <el-radio v-model="filterForm.wxProjectType" label="native">原生</el-radio>
        <el-radio v-model="filterForm.wxProjectType" label="Taro">Taro</el-radio>
      </el-form-item>
      <el-form-item prop="projectname" label="项目名称">
        <el-input v-model="filterForm.projectname" placeholder="请输入项目名称"></el-input>
      </el-form-item>
      <el-form-item prop="gitlaburl" label="Gitlab绑定" class="one-application">
        <el-select v-model="filterForm.gitlaburl" filterable remote reserve-keyword clearable placeholder="请输入关键词" :remote-method="remoteMethod" @change="gitlabChange" :loading="loading">
          <el-option v-for="item in options" :key="item.id" :label="item.url" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import api from '../api';
export default {
  name: 'miniProgramForm',
  props: {
    projectType: {
      type: String,
      default: 'web'
    },
    slug: {
      type: String,
      default: ''
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isLoading: {
      get () {
        return this.submitLoading;
      },
      set (val) {
        this.$emit('update:submitLoading', val);
      }
    }
  },
  data () {
    return {
      filterForm: {
        projectId: '',
        gitlaburl: '',
        gitlabname: '',
        wxProjectType: 'native',
        projectname: ''
      },
      rules: {
        projectname: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ],
        gitlaburl: [
          { required: true, message: '请绑定gitlab', trigger: 'change' }
        ],
        projectType: [
          { required: true, message: '请选择项目类型', trigger: 'change' }
        ],
      },
      options: [],
      loading: false
    }
  },
  methods: {
    // 远程搜索gitlab信息
    async remoteMethod (query) {
      if (query !== '') {
        this.loading = true;
        const { code, message, data } = await api.getRavenURLList({ key_word: query })
        this.loading = false;
        if (code === 200) {
          this.options = data
        } else {
          this.$message.error(message)
        }
      } else {
        this.options = [];
      }
    },
    // gitlab绑定值改变触发
    gitlabChange (v) {
      const item = this.options.find(item => item.id === v)
      if (item) {
        const { id, url, name } = item
        this.filterForm.gitlabname = name;
        this.filterForm.gitlaburl = url;
        this.filterForm.projectId = id;
      } else {
        this.filterForm.gitlabname = '';
        this.filterForm.gitlaburl = '';
        this.filterForm.projectId = '';
      }
    },
    // 表单提交
    submitForm () {
      this.$refs['filterForm'].validate((valid) => {
        if (valid) {
          this.defineData();
        } else {
          return false;
        }
      });
    },
    // 格式化数据
    async defineData () {
      this.isLoading = true;
      try {
        const params = {
          ...this.filterForm,
          slug: this.slug,
          project_type: this.projectType,
          projectType: this.filterForm.wxProjectType
        }
        let res = await api.createMiniPrograme(params);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '新增成功'
          })
          this.$router.replace(`/project?pid=${res.data.id}&type=mini_program`);
        }
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.mini-program-container {
  padding: 5px 25px 5px 0px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
}

.one-application {
  ::v-deep .el-select {
    width: 100%;
  }
}
</style>