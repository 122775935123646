<template>
  <div class="new-project-container">
    <Header />
    <div class="main-container">
      <el-form
        :model="filterForm"
        :rules="rules"
        ref="filterForm"
        label-width="100px"
        class="project-form"
        size="small"
      >
        <h3 class="title">新增项目</h3>
        <el-form-item label="项目类型" prop="projectType">
          <el-radio-group
            class="form-radio-group"
            v-model="filterForm.projectType"
            @input="projectTypeChange"
          >
            <el-radio label="web">Web(包括中后台,PC系统站点,H5应用)</el-radio>
            <!-- new -->
            <!-- <el-radio label="app">App</el-radio> -->
            <!-- <el-radio label="packages">离线包</el-radio> -->
            <!-- <el-radio label="packages_v2">new离线包</el-radio> -->
            <!-- <el-radio label="patch_app">热更新项目</el-radio> -->
            <!-- <el-radio label="mini_program">小程序</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="项目slug" prop="slug">
          <el-input
            class="form-inupt"
            v-model.trim="filterForm.slug"
            placeholder="请填入项目唯一标识,建议与gitlab保持一致"
            @input="checkSlugExist('filterForm')"
          ></el-input>
          <span v-if="!!filterForm.slug">
            <i
              class="el-icon-check"
              v-if="isCheck === true"
              :class="{ 'color-ok': isCheck === true }"
            ></i>
            <i
              class="el-icon-close"
              v-else-if="isCheck === false"
              :class="{ 'color-error': isCheck === false }"
            ></i>
          </span>
          <!-- <el-button @click="checkSlugExist('filterForm')" :class="{'color-green':isCheck}" class="check-btn" type="text" :disabled="!this.filterForm.slug">校验slug</el-button> -->
        </el-form-item>
        <el-divider content-position="left">{{ dividerName }}</el-divider>
        <!-- WEB -->
        <web-form
          v-if="filterForm.projectType === 'web'"
          ref="webForm"
          :projectType="filterForm.projectType"
          :slug="filterForm.slug"
          :submitLoading.sync="isLoading"
        ></web-form>
        <!-- APP -->
        <app-form
          v-else-if="filterForm.projectType === 'app'"
          ref="appForm"
          :projectType="filterForm.projectType"
          :slug="filterForm.slug"
          :submitLoading.sync="isLoading"
        ></app-form>
        <!-- 离线包 -->
        <offline-package-form
          v-else-if="filterForm.projectType === 'packages'"
          ref="offlinePackageForm"
          :projectType="filterForm.projectType"
          :slug="filterForm.slug"
          :submitLoading.sync="isLoading"
        ></offline-package-form>
        <!-- new 离线包 -->
        <new-offline-package-form
          v-else-if="filterForm.projectType === 'packages_v2'"
          ref="newOfflinePackageForm"
          :projectType="filterForm.projectType"
          :slug="filterForm.slug"
          :submitLoading.sync="isLoading"
        ></new-offline-package-form>
        <!-- 热更新 -->
        <hot-update-form
          v-else-if="filterForm.projectType === 'patch_app'"
          ref="hotUpdateForm"
          :projectType="filterForm.projectType"
          :slug="filterForm.slug"
          :submitLoading.sync="isLoading"
        ></hot-update-form>
        <!-- 小程序 -->
        <mini-program-form
          v-else-if="filterForm.projectType === 'mini_program'"
          ref="miniProgramForm"
          :projectType="filterForm.projectType"
          :slug="filterForm.slug"
          :submitLoading.sync="isLoading"
        ></mini-program-form>
      </el-form>
      <footer class="footer-container">
        <div class="btn-box">
          <el-button class="first-btn" @click="goback" size="small">取消</el-button>
          <el-button
            type="primary"
            @click="submitForm('filterForm')"
            size="small"
            :loading="isLoading"
            >确定</el-button
          >
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import api from "./api";
import Header from "@/layout/components/Header";
import WebForm from "./component/webForm.vue";
import AppForm from "./component/appForm.vue";
import OfflinePackageForm from "./component/offlinePackageForm.vue";
import NewOfflinePackageForm from "./component/newOfflinePackageForm.vue";
import MiniProgramForm from "./component/miniProgramForm.vue";
import HotUpdateForm from "./component/hotUpdateForm.vue";
const validateProjectSlug = (rule, value, callback) => {
  if (/^[A-Za-z_-]+$/.test(value)) {
    callback();
  } else {
    callback(new Error("slug不能是英文,-,_之外的字符"));
  }
};
export default {
  name: "newProject",
  components: {
    Header,
    WebForm,
    MiniProgramForm,
    AppForm,
    OfflinePackageForm,
    NewOfflinePackageForm,
    HotUpdateForm,
  },
  provide: function () {
    return { bigpan: () => false };
  },
  created() {
    window.addEventListener("keyup", this.goBackEvnet);
  },
  data() {
    return {
      filterForm: {
        projectType: "web",
        slug: "",
      },
      rules: {
        slug: [
          { required: true, message: "项目slug不能为空", trigger: "blur" },
          { max: 40, message: "slug长度不能超过40位", trigger: "blur" },
          { validator: validateProjectSlug, trigger: "blur" },
        ],
        projectType: [{ required: true, message: "请选择项目类型", trigger: "change" }],
      },
      dividerName: "Web",
      isLoading: false,
      isCheck: "",
      timer: "",
    };
  },
  methods: {
    // 返回上一页
    goback() {
      this.$router.go(-1);
    },
    goBackEvnet(e) {
      if (e.keyCode === 27) {
        this.goback();
      }
    },
    // 校验slug
    checkSlugExist(formName) {
      clearTimeout(this.timer);
      this.isCheck = "";
      this.timer = setTimeout(() => {
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const res = await api.checkSlugExist({ slug: this.filterForm.slug });
            if (!res) {
              this.isCheck = false;
            } else {
              // this.$message.success(res.message);
              this.isCheck = true;
            }
          }
        });
      }, 400);
    },
    // 提交按钮触发
    submitForm(formName) {
      if (!this.isCheck) {
        return this.$message.error("slug校验不成功！");
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (this.filterForm.projectType) {
            case "web":
              this.$refs["webForm"].submitForm();
              break;
            case "app":
              this.$refs["appForm"].submitForm();
              break;
            case "packages":
              this.$refs["offlinePackageForm"].submitForm();
              break;
            case "packages_v2":
              this.$refs["newOfflinePackageForm"].submitForm();
              break;
            case "patch_app":
              this.$refs["hotUpdateForm"].submitForm();
              break;
            default:
              this.$refs["miniProgramForm"].submitForm();
              break;
          }
        } else {
          return false;
        }
      });
    },
    // 项目类型改变触发
    projectTypeChange(key) {
      switch (key) {
        case "web":
          this.dividerName = "Web";
          break;
        case "app":
          this.dividerName = "App";
          break;
        case "packages":
          this.dividerName = "离线包";
          break;
        case "packages_v2":
          this.dividerName = "new离线包";
          break;
        case "patch_app":
          this.dividerName = "热更新项目";
          break;
        default:
          this.dividerName = "小程序";
          break;
      }
    },
  },
  destroyed() {
    window.removeEventListener("keyup", this.goBackEvnet);
  },
};
</script>
<style lang="scss" scoped>
.new-project-container {
  background: #f2f3f5;
  height: 100vh;
  .main-container {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 16px 16px 16px;
    height: calc(100% - 90px);
    background: #fff;
    border-radius: 8px;
    .project-form {
      width: 1000px;
      overflow-y: auto;
      .title {
        margin: 30px 0 20px 30px;
        font-size: 20px;
        font-weight: 600;
        color: #000;
      }
      .form-inupt {
        width: 312px;
      }
      // .check-btn {
      //   margin-left: 12px;
      //   &.color-green {
      //     display: block;
      //     color: #078080;
      //   }
      // }
      .color-ok {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        color: white;
        background-color: green;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-left: 10px;
      }
      .color-error {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        color: white;
        background-color: rgb(174, 5, 5);
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin-left: 10px;
      }
      .form-select {
        width: 160px;
      }
      .form-radio-group {
        display: contents;
      }
    }
  }
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 64px;
    line-height: 64px;
    text-align: center;
    border-top: 1px solid #e5e6eb;
    box-sizing: content-box;
    .btn-box {
      width: 1000px;
      text-align: right;
    }
  }
}
</style>
