// 定时器防抖
export function antiShake (fn, wait, arg) {
  if (fn.timer) {
    clearTimeout(fn.timer)
  }
  fn.timer = setTimeout(() => {
    fn(arg);
  }, wait)
}
// 定时器节流
export function throttle (fn, wait, arg) {
  if (!fn.timer) {
    fn(arg);
    fn.timer = setTimeout(function () {
      fn.timer = null;
    }, wait)
  }
}
