<template>
  <div class="message-edit-container">
    <el-form :model="form" ref="form" label-width="100px" size="small">
      <el-form-item label="消息类型" prop="message_type">
        <el-select class="form-select" v-model="form.message_type" placeholder="请选择消息类型" filterable disabled>
          <el-option v-for="item in messageTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="通知类型" prop="message_post_type">
        <el-select class="form-select" v-model="form.message_post_type" placeholder="请选择通知类型">
          <el-option label="站内通知" :value="1"></el-option>
          <el-option label="企业微信" :value="2"></el-option>
          <el-option label="两者都通知" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select class="form-select" v-model="form.status" placeholder="请选择消息配置是否开启">
          <el-option label="开启" :value="0"></el-option>
          <el-option label="停用" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息接收者" prop="message_post_type">
        <el-select class="form-select" @change="handleInputConfirm" v-model="form.received_user" placeholder="请选择消息接收者" clearable filterable remote :remote-method="queryEmpUserList" :loading="loading">
          <el-option v-for="item in empUserList" :key="item.empId" :label="item.empName" :value="item.empId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="editMessageSetting">保存</el-button>
        <el-button @click="resetFormData">取消</el-button>
      </el-form-item>
    </el-form>
    <div class="tags-container">
      <span v-if="!dynamicTags.length" class="container-placeholder">暂无消息接收者</span>
      <template v-else>
        <el-tag class="tag" :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="removeTag(tag)">
          {{tag}}
        </el-tag>
      </template>
    </div>
  </div>
</template>

<script>
import api from '../../views/home/api';
export default {
  props: {
    editData: {
      type: Object,
      default: () => { }
    }
  },
  data () {
    return {
      form: {
        message_type: 1,
        message_post_type: 1,
        received_user: '',
        status: 0
      },
      dynamicTags: [],  // tags
      tableData: [],  // 表格数据
      messageTypeList: [], // 消息类型列表
      messageId: -1, // 当前messageId
      baseLoading: '',
      loading: false,
      searchStr: '',
      empUserList: []
    }
  },
  mounted () {
    this.queryEmpUserList();
    this.getAllMessageType();
    this.getEditMessageData(this.editData);
  },
  methods: {
    // 获取OA人员信息
    async queryEmpUserList (searchStr) {
      this.loading = true;
      const res = await api.queryEmpUserList({ searchStr });
      if (res.code === 200) {
        this.empUserList = res.data.list;
      } else {
        this.$message.error('服务异常！')
      }
      this.loading = false;
    },
    // 获取所有消息列表
    async getAllMessageType () {
      const res = await api.getAllMessageType();
      const list = [];
      if (res && res.data) {
        for (const key in res.data) {
          list.push(res.data[key])
        }
      }
      this.messageTypeList = list;
    },
    // 获取消息编辑数据
    getEditMessageData (row) {
      this.dynamicTags = [];
      this.messageId = row.id;
      this.form.message_type = row.message_type;
      this.form.message_post_type = row.message_post_type;
      this.form.status = row.status;
      this.dynamicTags = row.received_user.split(',');
    },
    // 编辑消息信息
    async editMessageSetting () {
      try {
        if (!this.dynamicTags.length) {
          return this.$message.warning('消息接收者不能为空！');
        }
        const message = this.messageTypeList.find(item => item.value === this.form.message_type) || {};
        const params = {
          project_id: this.editData.project_id,
          slug: this.editData.slug,
          message_type: message.value,
          message_name: message.label,
          message_post_type: this.form.message_post_type,
          status: this.form.status,
          received_user: this.dynamicTags.join(','),
          id: this.messageId
        }
        const res = await api.editMessageSetting(params);
        if (res && res.code === 200) {
          this.$emit('getMessageSettingList');
          this.$message.success('修改成功！');
        }
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.resetFormData();
      }
    },
    // 重置表单数据
    resetFormData () {
      this.dynamicTags = [];
      this.$refs['form'].resetFields();
      this.$emit('close');
    },
    // 移除tag
    removeTag (tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },
    // 添加
    handleInputConfirm () {
      let inputValue = this.form.received_user;
      if (inputValue) {
        const user = this.empUserList.find(item => item.empId === inputValue)
        // 如果用户存在
        if (user && user.accountInfo && user.accountInfo.length) {
          // 寻找主账号
          const mainAccount = user.accountInfo.find(item => item.mainAccount);
          // 如果主账号存在
          if (mainAccount) {
            const val = `${mainAccount.wxUserCode}#${user.empName}`;
            if (!this.isExistTag(val)) {
              this.dynamicTags.push(val);
            } else {
              this.$message.warning('成员已经存在！');
            }
          } else {
            this.$message.warning('该成员数据结构错误：未找到该用户的主账号。');
          }
        }
      }
      this.form.received_user = '';
    },
    // 判断是否存在
    isExistTag (val) {
      return this.dynamicTags.some(item => item === val)
    }
  }
}
</script>

<style lang="scss" scoped>
.message-edit-container {
  display: flex;
  .form-select {
    width: 300px;
  }
  .tags-container {
    margin-left: 10px;
    padding: 5px 8px;
    width: 100%;
    min-height: 120px;
    max-height: 360px;
    border: 1px solid rgb(220, 223, 230);
    border-radius: 6px;
    overflow-y: auto;
    .container-placeholder {
      color: #a2a2a2;
    }
    .tag {
      margin-top: 4px;
      margin-left: 4px;
    }
  }
}
</style>