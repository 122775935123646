<template>
  <fragment class="sidebar-container" v-if="!item.hidden">
    <!-- has only child -->
    <template v-if="hasOnlyChild(item.children, item)">
      <el-menu-item :index="item.path">
        <i :class="childItem.iconClass ? childItem.iconClass : ''"></i>
        <span slot="title">{{ childItem.name }}</span>
      </el-menu-item>
    </template>

    <el-submenu v-else :index="item.path || '/'">
      <template slot="title">
        <i :class="item.iconClass ? item.iconClass : ''"></i>
        <span>{{ item.name }}</span>
      </template>

      <!-- component recursion -->
      <sidebar-item
        v-for="child in item.children"
        :key="child.path"
        :item="child"
      />
    </el-submenu>
  </fragment>
</template>

<script>
import { Fragment } from 'vue-fragment'
export default {
  name: 'SidebarItem',
  components: {
    Fragment
  },
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    basePath: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      childItem: null
    }
  },
  beforeUpdate() {
    this.$forceUpdate()
  },
  methods: {
    // 路由处理 hidden、只有一个children
    hasOnlyChild(children = [], item) {
      let newChildren = children.filter(obj => {
        return obj.hidden ? false : true
      })
      if (newChildren.length === 1) {
        this.childItem = newChildren[0]
        return true
      }
      if (newChildren.length === 0) {
        this.childItem = { ...item, noChild: true }
        return true
      }
      return false
    },

    // 路由地址拼接
    resolvePath: function (router) {
      
    }
  }
}
</script>

<style lang="scss">
 
</style>
