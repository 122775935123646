/* eslint-disable */
import Vue from "vue";
import { Loading } from "element-ui";
import { registerMicroApps, start, loadMicroApp, addGlobalUncaughtErrorHandler, setDefaultMountApp } from "qiankun";
// import menu from "../menu.js";
import menu from '../config/index'
import router from "../router";
import store from '../store'
import eventBus from "./eventBus.js";
// import NProgress from "nprogress";
// import "nprogress/nprogress.css";
import { loading, baseLoading } from './loading'

//匹配路由
function genActiveRule (routerPrefix) {
  return (location) => location.pathname.startsWith(routerPrefix);
}
function hackStr () {
  function hack () {
    if (window.__POWERED_BY_QIANKUN__) {
      var RawXMLHttpRequest = window.XMLHttpRequest;
      window.XMLHttpRequest = function () {
        const instance = new RawXMLHttpRequest();
        const rawOpen = instance.open;
        instance.open = function (method, url, async) {
          let resolvedUrl = url;
          try {
            resolvedUrl = new URL(url).toString();
          } catch {
            resolvedUrl = new URL(
              url.replace(/^\//, ""),
              window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__
            );
          }
          rawOpen.call(this, method, resolvedUrl, async);
        };
        return instance;
      };
    }
  }
  return hack.toString();
}

/**
 * qiankun fetch重写
 * 动态寻找入口文件app.js 并动态注入publicPath
 * 动态注入hack代码
 * @param {string} url
 * @param  {...any} args
 * @returns {Promise}
 */
function customFetch (url, ...args) {
  let promise = null;
  let urlObj = {};
  try {
    urlObj = new URL(url);
  } catch {
    urlObj.pathname = "";
  }
  if (
    urlObj.pathname.endsWith(".js") &&
    urlObj.pathname
      .split("/")
      .slice(-1)[0]
      .includes("app")
  ) {
    // 动态寻找app.js入口注入public-path
    // 劫持请求库
    promise = fetch(url, ...args).then(async res => {
      const text = await res.text();
      const newScript =
        `;!${hackStr()}();` +
        text.replace(
          /=\s*"\/(\S*)"/,
          '=window.__INJECTED_PUBLIC_PATH_BY_QIANKUN__+"$1"'
        );
      return {
        async text () {
          return newScript;
        }
      };
    });
  } else {
    promise = fetch(url, ...args);
  }
  return promise;
}

/**
 * 生成子应用entry地址
 * @param {string} url
 * @returns {string}
 */
function getPublicPath (url) {
  let newUrl;
  try {
    newUrl = new URL(url).toString();
  } catch {
    newUrl = new URL(url, location.origin).toString();
  }
  return newUrl.endsWith("/") ? newUrl : newUrl + "/";
}

/**
 * 动态修改子应用index.html引用资源路径
 * @param {string} str
 * @returns {string}
 */
function getTemplate (str) {
  const code = str.replace(/(?:src|href)="(\S*)"/g, function (str, sub) {
    if (sub.startsWith("/") && !sub.startsWith("//")) {
      const newSub = sub.replace(/\/+/, "");
      return str.replace(sub, newSub);
    } else {
      return str;
    }
  });
  return code;
}


/**
 *
 * @param {string} type href || open
 * @param {string} url 需要跳转的地址
 */

function goOutLink (type, url) {
  if (url && type) {
    if (type === "href") {
      window.location[type] = url;
    } else if (type === "open") {
      window[type](url);
    }
  }
}

export function startKeepAliveApps () {
  const keepaliveLoaderInstance = Loading.service({
    fullscreen: true,
    spinner: "cyc-loading-icon"
  });
  const query = new URLSearchParams(location.search);
  if (query.get("code")) {
    window.location.href = window.location.origin;
    return;
  }
  const apps = [];
  const micoSystemList = menu.micoSystemList.filter(v => v.keepAlive);
  // for (const val of menu.micoSystemList) {
  for (const val of micoSystemList) {
    Vue.prototype.kylin.goLogin = () => {
      window.$globalOidc.signoutRedirect();
    };
    // Vue.prototype.kylin.changeValue = (key, value) => {
    //   Vue.prototype.kylin[key] = value;
    // };
    const { entry, microName, activeRule } = val;
    const params = {
      name: microName,
      entry,
      container: "#keepaliveApp",
      activeRule,
      props: {
        // emitter: new EventEmitter(microName),
        goOutLink,
        kylin: Vue.prototype.kylin,
        portalRouter: router,
        parentVuex: store,
        baseEventBus: eventBus,
        baseLoading: baseLoading // function 传递给子应用调用全局loading，返回loading实列，关闭得用返回的 loading.close()方法调用
      }
    };
    apps.push(params);
  }
  const app = apps[0];
  const microStatus = loadMicroApp(app);
  microStatus.loadPromise.then(res => {
    keepaliveLoaderInstance.close();
  });
}

export function startMicroApps () {
  const query = new URLSearchParams(location.search);
  if (query.get("code")) {
    // window.location.href = window.location.origin;
    return;
  }
  const apps = [];
  const micoSystemList = menu.micoSystemList.filter(v => !v.keepAlive);
  for (const val of micoSystemList) {
    Vue.prototype.kylin.goLogin = () => {
      if (window.kylinOidcAuth) {
        window.kylinOidcAuth.logout(process.env.VUE_APP_HOME_URL);
      } else {
        window.$globalOidc.signoutRedirect();
      }
    };
    Vue.prototype.kylin.logout = () => {
      if (window.kylinOidcAuth) {
        window.kylinOidcAuth.logout();
      }
    }
    // Vue.prototype.kylin.changeValue = (key, value) => {
    //   Vue.prototype.kylin[key] = value;
    // };
    const { entry, microName, activeRule } = val;
    const params = {
      name: microName,
      entry,
      // container: '#appContainer',
      container: "#appContainer",
      activeRule: genActiveRule(activeRule),
      loader: loading,
      props: {
        goOutLink,
        kylin: Vue.prototype.kylin,
        portalRouter: router,
        parentVuex: store,
        baseEventBus: eventBus,
        baseLoading: baseLoading,

      }
    };
    apps.push(params);
  }
  registerMicroApps(apps, {
    // qiankun 生命周期钩子 - 微应用加载前
    beforeLoad: (app) => {
      // 加载微应用前，加载进度条
      // NProgress.start();
      return Promise.resolve();
    },
    // qiankun 生命周期钩子 - 微应用挂载后
    afterMount: (app) => {
      // 加载微应用前，进度条加载完成
      // NProgress.done();
      return Promise.resolve();
    },
  });
  /**
  * 添加全局的未捕获异常处理器
  */
  addGlobalUncaughtErrorHandler((event) => {
    const { message: msg } = event;
    // console.error(event,msg);
    // 加载失败时提示
    // console.error(event);
    // Message.error('微应用加载失败，请检查应用是否可运')
    if (msg && msg.includes("died in status LOADING_SOURCE_CODE")) {
      console.error("微应用加载失败，请检查应用是否可运行");
    }
  });
  //设置默认初始入口
  // setDefaultMountApp("/app-main#/");
  if (process.env.NODE_ENV === "development") {
    start({
      // fetch: customFetch,
      // getPublicPath,
      // getTemplate,
      // singular: false
      // prefetch: false
      sandbox: {
        experimentalStyleIsolation: true,

      },
    });
  } else {
    start(
      {
        sandbox: {
          experimentalStyleIsolation: true,
        },
      }
    );
  }
}
