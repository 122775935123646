<template>
  <div class="project-pannel">
    <Header class="header-top"></Header>
    <div
      class="project-content"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="cyc-loading-icon"
      element-loading-background="rgba(255, 254, 254, 0.8)"
      @scroll="windowScroll"
      ref="drawer"
    >
      <div class="project-drawer-container" ref="drawer">
        <div class="card-header">
          <div class="search-input">
            <el-input
              ref="input"
              suffix-icon="el-icon-search"
              style="border: none"
              clearable
              placeholder="输入关键字进行过滤"
              v-model="filterText"
              @input="filter"
            >
            </el-input>
            <el-button @click="newProject" icon="el-icon-plus">新增项目</el-button>
            <el-button @click="openGroup" icon="el-icon-plus">新增分组</el-button>
          </div>
          <div style="margin-top: 10px">
            <el-radio-group v-model="radio" @change="changeIdenty">
              <!-- <el-radio label="all">所有</el-radio>
              <el-radio label="web">web</el-radio>
              <el-radio label="app">app</el-radio>
              <el-radio label="mini">mini</el-radio> -->
            </el-radio-group>
          </div>
        </div>
        <div class="card-content" v-for="group in showList" :key="group.id">
          <h2 v-if="group.projects && group.projects.length" class="title">
            {{ `${group.group_name}（${group.projects.length}）` }}
          </h2>
          <template v-if="!group.p_type">
            <div
              @mouseenter="initTargetDom(project)"
              @contextmenu.prevent="handleContextmenu(project)"
              @click="changeProject(project)"
              class="card-item"
              :class="{ isActive: currentProject.slug === project.slug }"
              v-for="project in group.projects"
              :key="`${project.project_type}_${project.id}`"
              :title="project.project_name"
              :ref="`project_${project.slug}`"
            >
              <div @click.stop="() => {}" v-if="project.is_offline" class="offline-bg"></div>
              <el-tag
                class="tag"
                size="mini"
                v-if="project.template_type !== 'default'"
                :type="getTypeClass(project.template_type)"
                >{{ project.template_type }}</el-tag
              >
              <img v-if="project.logo" class="logo" :src="project.logo" />
              <div v-else class="logo" :class="`color-${getColorName(project.project_name)}`">
                {{ project.project_name[0] }}
              </div>
              <div class="text">
                <div class="name text-overflow-ellipsis">
                  {{ project.project_name + (project.is_offline ? "（下线中）" : "") }}
                </div>
                <div class="slug text-overflow-ellipsis">{{ project.slug }}</div>
              </div>
              <i
                class="favorite-icon"
                :class="{
                  'el-icon-star-off': !project.is_favorite,
                  'el-icon-star-on color-yellow': project.is_favorite,
                }"
                @click.stop="handleFavorite(project)"
                :title="project.is_favorite ? '取消收藏' : '收藏'"
              ></i>
            </div>
          </template>
          <template v-else-if="group.p_type === 'app'">
            <div
              @click="changeProject(project)"
              class="card-item bcg-melons"
              :class="{
                isActive:
                  currentProject.id === project.id &&
                  currentProject.project_type === project.project_type,
              }"
              v-for="project in group.projects"
              :key="`${project.project_type}_${project.id}`"
              :title="project.project_name"
            >
              <el-tag v-if="project.isHotfix" class="tag" size="mini" type="warning">{{
                "APP&热更新"
              }}</el-tag>
              <el-tag v-else class="tag" size="mini" :type="getTypeClass(project.template_type)">{{
                "APP"
              }}</el-tag>

              <img v-if="project.logoUrl" class="logo" :src="project.logoUrl" />
              <div v-else class="logo" :class="`color-blue`">{{ "APP" }}</div>
              <div class="text">
                <div class="name text-overflow-ellipsis">{{ project.appName }}</div>
                <div class="slug text-overflow-ellipsis">{{ project.appId }}</div>
              </div>
              <div>
                <img
                  class="type-iconf-left"
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/APP (5).png"
                />
                <img
                  class="type-iconf"
                  v-if="project.platform !== 'android'"
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/apple.png"
                />
                <img
                  class="type-iconf"
                  v-else
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/安卓.png"
                />
              </div>
            </div>
          </template>
          <template v-else-if="group.p_type === 'packages'">
            <div
              @click="changeProject(project)"
              class="card-item bcg-melons"
              :class="{
                isActive:
                  currentProject.id === project.id &&
                  currentProject.project_type === project.project_type,
              }"
              v-for="project in group.projects"
              :key="`${project.project_type}_${project.id}`"
              :title="project.project_name"
            >
              <el-tag class="tag" size="mini" :type="getTypeClass(project.template_type)">{{
                "package"
              }}</el-tag>
              <div class="logo off-line-icon" :class="`color-blue`">{{ "离线包" }}</div>
              <div class="text">
                <div class="name text-overflow-ellipsis">{{ project.projectName }}</div>
                <div class="slug text-overflow-ellipsis">{{ project.desc }}</div>
              </div>
              <div>
                <img
                  class="type-iconf-left"
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/APP (5).png"
                />
              </div>
            </div>
          </template>
          <template v-else-if="group.p_type === 'packages_v2'">
            <div
              @click="changeProject(project)"
              class="card-item bcg-melons"
              :class="{
                isActive:
                  currentProject.id === project.id &&
                  currentProject.project_type === project.project_type,
              }"
              v-for="project in group.projects"
              :key="`${project.project_type}_${project.id}`"
              :title="project.project_name"
            >
              <el-tag class="tag" size="mini" :type="getTypeClass(project.template_type)">{{
                "package"
              }}</el-tag>
              <div class="logo off-line-icon" :class="`color-blue`">{{ "*离线包" }}</div>
              <div class="text">
                <div class="name text-overflow-ellipsis">{{ project.projectName }}</div>
                <div class="slug text-overflow-ellipsis">{{ project.desc }}</div>
              </div>
              <div>
                <img
                  class="type-iconf-left"
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/APP (5).png"
                />
              </div>
            </div>
          </template>
          <!-- <template v-else-if="group.p_type === 'patch_app'">
            <div @click="changeProject(project)" class="card-item bcg-melons " :class="{isActive: currentProject.id ===project.id&&currentProject.project_type===project.project_type}" v-for="project in group.projects" :key="`${project.project_type}_${project.id}`" :title="project.project_name">
              <el-tag class="tag" size="mini" :type="getTypeClass(project.template_type)">{{'Hotfix'}}</el-tag>
              <img v-if="project.logoUrl" class="logo" :src="project.logoUrl" />
              <div v-else class="logo off-line-icon" :class="`color-blue`">{{"热更新"}}</div>
              <div class="text">
                <div class="name text-overflow-ellipsis">{{project.appName}}</div>
                <div class="slug text-overflow-ellipsis">{{project.appId}}</div>
              </div>
              <div>
                <img class="type-iconf-left" src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/APP (5).png">
                <img class="type-iconf" v-if="project.platform!=='android'" src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/apple.png" />
                <img class="type-iconf" v-else src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/安卓.png" />
              </div>
            </div>
          </template> -->
          <template v-else-if="group.p_type === 'mini_program'">
            <div
              @click="changeProject(project)"
              class="card-item bcg-raven"
              :class="{
                isActive:
                  currentProject.id === project.id &&
                  currentProject.project_type === project.project_type,
              }"
              v-for="project in group.projects"
              :key="`${project.project_type}_${project.id}`"
              :title="project.project_name"
            >
              <el-tag class="tag" size="mini" :type="getTypeClass(project.template_type)">{{
                "miniProgram"
              }}</el-tag>
              <img v-if="project.logoUrl" class="logo" :src="project.logoUrl" />
              <div v-else class="logo off-line-icon" :class="`color-blue`">{{ "小程序" }}</div>
              <div class="text">
                <div class="name text-overflow-ellipsis">{{ project.projectname }}</div>
                <div class="slug text-overflow-ellipsis">{{ project.gitlabname }}</div>
              </div>
              <div>
                <img
                  class="type-iconf-left"
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/小程序 (2).png"
                />
                <img
                  class="type-iconf"
                  v-if="project.projectType !== 'Taro'"
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/mini1.png"
                />
                <img
                  class="type-iconf"
                  style="background: blue; height: 18px; width: 18px"
                  v-else
                  src="https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/logo-taro.png"
                />
              </div>
            </div>
          </template>
        </div>

        <div class="float-icon-box" ref="float-icon-box">
          <el-tooltip effect="dark" content="刷新项目列表" placement="left">
            <i class="el-icon-refresh refresh" @click="searchGroup(false, true)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="定位当前项目" placement="left">
            <i class="el-icon-aim aim" @click="gotoActiveProject(false)"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="显示收藏项目" placement="left">
            <i class="el-icon-star-off aim" @click="showFavoriteProject"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="回到顶部" placement="left">
            <i class="el-icon-top aim" @click="goToHead"></i>
          </el-tooltip>
          <el-tooltip effect="dark" content="关闭弹窗（ESC）" placement="left">
            <i class="el-icon-close close" @click="goback"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
    <ConfirmDialog :visible="dialogVisible" :dialogOpting="dialogOpting" @handleClose="handleClose">
      <div class="love-project">
        <LoveProejct
          @handleFavorite="handleFavorite"
          @handleChangeCurrentProjectEv="changeProject"
        ></LoveProejct>
      </div>
    </ConfirmDialog>
    <el-dialog :visible.sync="visibleDialogNewGroup" title="新增分组">
      <el-form :model="form" ref="form" :rules="rule" label-width="80px" size="small">
        <el-form-item label="分组名称" prop="groupName">
          <el-input v-model="form.groupName"></el-input>
        </el-form-item>
        <div style="display: flex; justify-content: center">
          <el-button @click="newGroup" type="primary" size="small">确认</el-button>
        </div>
      </el-form>
    </el-dialog>
    <context-menu
      class="context-menu"
      :target="contextMenuTarget"
      :show="contextMenuVisible"
      @update:show="(show) => (contextMenuVisible = show)"
    >
      <a v-if="!currentRightMenuProject.is_offline" href="javascript:;" @click="favoriteProject">{{
        currentRightMenuProject.is_favorite ? "取消收藏" : "收藏项目"
      }}</a>
      <a href="javascript:;" @click="handleOffline">{{
        currentRightMenuProject.is_offline ? "取消下线" : "下线项目"
      }}</a>
    </context-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import Header from "@/layout/components/Header";
import Api from "./api.js";
import { MessageBox } from "element-ui";
import ConfirmDialog from "@/components/Comfirm/index.vue";
import ContextMenu from "@/components/ContextMenu/index.vue";
import LoveProejct from "./component/loveProject.vue";
import tool from "@/utils/tool.js";
import merge from "webpack-merge";
export default {
  components: { Header, ConfirmDialog, LoveProejct, ContextMenu },
  provide: function () {
    return { bigpan: () => false };
  },
  watch: {
    $route(n, o) {
      if (n.path === o.path) {
        this.$router.go(-1);
      }
    },
  },
  data() {
    return {
      rule: {
        groupName: [{ required: true, message: "分组名称必须选择" }],
      },
      list: [],
      filterText: "",
      form: {
        groupName: "",
      },
      colorsName: [
        "blue",
        "indigo",
        "purple",
        "pink",
        "red",
        "orange",
        "yellow",
        "green",
        "teal",
        "cyan",
        "primary",
        "info",
        "warning",
        "danger",
        "light",
      ],
      showAddIcon: false,
      dialogInjectData: {},
      showSearch: false,
      loading: false,
      dialogVisible: false,
      visibleDialogNewGroup: false,
      dialogOpting: {
        title: "我的收藏",
        layout: "center",
        footLayout: "left",
        hiddenFoot: true,
        width: "80%",
      },
      excludeRoute: ["newProject", "project"],
      radio: "web",
      contextMenuTarget: "",
      contextMenuVisible: false,
      currentRightMenuProject: "",
    };
  },
  computed: {
    ...mapGetters("project", {
      cg: "currentGroup",
      searchProjectString: "searchProjectString",
      myFavoriteProject: "myFavoriteProject",
    }),
    ...mapGetters(["currentProject"]),

    showList() {
      // 深拷贝list数据
      const deepCopyList = JSON.parse(JSON.stringify(this.list));
      const showList = [];
      deepCopyList.forEach((l) => {
        const lObj = l;
        // 过滤组名称匹配
        if (this.filterNode(this.filterText, l)) {
          return showList.push(lObj);
        }
        const projects = [];
        l.projects.forEach((p) => {
          // 过滤项目名称匹配
          p.p_type = l.p_type;
          if (this.filterNode(this.filterText, p)) {
            projects.push(p);
          }
        });
        if (projects.length) {
          lObj.projects = projects;
          showList.push(lObj);
        }
      });
      return showList;
    },
  },
  async mounted() {
    window.addEventListener("keyup", this.goBackEvnet);
    // 获取localstorage
    const identity = window.localStorage.getItem("medusa_p_identity") || "web";
    this.radio = identity;
    this.init();
    this.$nextTick(() => {
      this.$refs["input"].focus();
    });
  },
  methods: {
    openGroup() {
      this.visibleDialogNewGroup = true;
      this.form.groupName = "";
    },
    async newGroup() {
      await this.$refs.form.validate();
      await Api.newGroup({ group_name: this.form.groupName });
      this.$message.success("新增成功");
      this.visibleDialogNewGroup = false;
    },
    // 下线项目
    handleOffline() {
      this.$confirm(
        `您是否要${this.currentRightMenuProject.is_offline ? "取消下线" : "下线"}【${
          this.currentRightMenuProject.project_name
        }】项目?`,
        "操作提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        },
      ).then(async () => {
        const res = await Api.offlineProject({
          id: this.currentRightMenuProject.id,
          is_offline: !this.currentRightMenuProject.is_offline,
        });
        if (res.code === 200) {
          this.searchGroup();
          this.$message.success(res.message || "操作成功");
        }
        this.contextMenuVisible = false;
      });
    },
    // 收藏项目
    favoriteProject() {
      this.handleFavorite(this.currentRightMenuProject);
      this.contextMenuVisible = false;
    },
    // 初始化targetDom
    initTargetDom(project) {
      this.$nextTick(() => {
        this.contextMenuTarget = this.$refs[`project_${project.slug}`][0];
      });
    },
    // 右击事件
    handleContextmenu(project) {
      this.currentRightMenuProject = project;
      this.$nextTick(() => {
        this.contextMenuTarget = this.$refs[`project_${project.slug}`][0];
      });
    },
    // 判断url是否携带参数,对其进行处理
    async handleQueryParams() {
      let queryObj = tool.getAllQueryParams();
      // 查询queryObj.pid的对象
      let queryProject;
      if (queryObj.pid) {
        for (let i = 0; i < this.list.length; i++) {
          let groupItem = this.list[i];
          let projects = groupItem["projects"];
          if (projects && projects.length) {
            for (let j = 0; j < projects.length; j++) {
              let projectItem = projects[j];
              if (projectItem.id == queryObj.pid) {
                queryProject = projectItem;
                break;
              }
            }
          }
        }
      }
      if (queryProject) {
        await this.setCurrentProject(queryProject);
        this.gotoActiveProject();
      } else if (this.currentProject.id) {
        this.gotoActiveProject();
      }
      return;
    },
    newProject() {
      this.$router.push("/newProject");
    },
    ...mapActions("project", ["setCurrentProject"]),
    getTypeClass(key) {
      let typeClass;
      switch (key) {
        case "bp":
          typeClass = "primary";
          break;
        case "jarvis":
          typeClass = "success";
          break;
        case "ssr":
          typeClass = "warning";
          break;
        case "ssrActivity":
          typeClass = "danger";
          break;
        default:
          typeClass = "primary";
          break;
      }
      return typeClass;
    },
    // 窗口滚动事件
    windowScroll(e) {
      const height = e.target.scrollTop;
      if (height > 100) {
        this.$refs.input.$el.classList.add("hig-input");
      } else {
        this.$refs.input.$el.classList.remove("hig-input");
      }
    },
    // 获取颜色名称
    getColorName(name) {
      if (name) {
        return this.colorsName[
          name.charCodeAt(0) % (this.colorsName && this.colorsName.length) || 0
        ];
      } else {
        return "blue";
      }
    },
    // 日期格式化
    dateFormat(date) {
      return moment(new Date(date)).format("YYYY-MM-DD hh:mm");
    },
    // 初始化
    async init(identity) {
      await this.searchGroup();
      this.handleQueryParams();
      this.filterText = this.searchProjectString;
    },
    async searchGroup(urlQuery) {
      this.loading = true;
      const radio = this.radio;
      let rows = await Api.list({ role: radio });
      let childList = await Api.getChildList();
      let favoriteList = [];
      this.loading = false;
      if (radio === "all") {
        this.list = rows.data;
      } else if (radio === "web") {
        this.list = rows.data.filter((item) => !item.p_type);
      } else if (radio === "app") {
        this.list = rows.data.filter(
          (item) =>
            item.p_type === "packages_v2" || item.p_type === "packages" || item.p_type === "app",
        );
      } else if (radio === "mini") {
        this.list = rows.data.filter((item) => item.p_type === "mini_program");
      }
      rows.data.forEach((item) => {
        favoriteList.push(...item.projects.filter((p) => p.is_favorite));
      });
      if (childList.data.length > 0) {
        const diyGroup = {
          group_name: "我的应用",
          id: 999,
          project_number: childList.data.length,
          projects: childList.data,
          type: "group",
        };
        this.list.push(diyGroup);
        favoriteList.push(...childList.data.filter((p) => p.is_favorite));
      }
      this.$store.commit("project/groupList@persistence", this.list); // 保存分组列表下发给子应用使用（变量使用场景：反馈--项目列表）
      this.$store.commit("project/myFavoriteProject@persistence", favoriteList);
      this.$nextTick(() => {
        if (this.filterText) {
          this.filter(this.searchProjectString);
        }
      });
    },
    filter(val) {
      this.filterNode(val, this.list);
      this.$store.commit("project/searchProjectString@persistence", val);
    },
    filterNode(value, data) {
      if (!value) return true;

      // 快捷搜索收藏项目
      if (value === "·") {
        return data.is_favorite;
      }

      if (data.group_name) {
        return data.group_name.indexOf(value) !== -1;
      }

      if (data.project_name) {
        return data.project_name.indexOf(value) !== -1 || data.slug.indexOf(value) !== -1;
      }

      // 离线包、new离线包
      if (data.name && ["packages", "packages_v2"].includes(data.p_type)) {
        return data.name.indexOf(value) !== -1 || (data.desc && data.desc.indexOf(value) !== -1);
      }

      // 小程序
      if (data.projectname && data.p_type === "mini_program") {
        return (
          data.projectname.indexOf(value) !== -1 ||
          (data.gitlabname && data.gitlabname.indexOf(value) !== -1)
        );
      }

      // app、热更新
      if (data.appName && ["app"].includes(data.p_type)) {
        return (
          data.appName.indexOf(value) !== -1 || (data.appId && data.appId.indexOf(value) !== -1)
        );
      }
    },
    isSsrProject(val) {
      if (!val) return false;
      return val.template_type === "ssr" || val.template_type === "ssrActivity";
    },
    showMenu($event) {
      $event.preventDefault();
      return false;
    },
    gotoActiveProject(immediate = true) {
      let dom = document.querySelector(".card-item.isActive");
      if (dom) {
        let hei = document.querySelector(".card-item.isActive").offsetTop - 100;
        if (immediate) {
          this.$refs["drawer"].scrollTo(0, hei);
        } else {
          this.srollTop(hei, 200);
        }
      }
    },
    srollTop(number = 0, time) {
      if (!time) {
        this.$refs["drawer"].scrollTop = number;
        return number;
      }
      const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
      let spacingInex = time / spacingTime; // 计算循环的次数
      let nowTop = this.$refs["drawer"].scrollTop; // 获取当前滚动条位置
      let everTop = (number - nowTop) / spacingInex; // 计算每次滑动的距离
      let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--;
          this.srollTop((nowTop += everTop));
        } else {
          clearInterval(scrollTimer); // 清除计时器
        }
      }, spacingTime);
    },
    // 切换项目
    async changeProject(project) {
      switch (project.project_type) {
        case "web":
          this.changeWebProject(project);
          break;
        case "app":
          this.changeAppProject(project);
          break;
        case "packages":
          this.changePackagesProject(project);
          break;
        case "packages_v2":
          this.changeNewPackagesProject(project);
          break;
        default:
          this.changeMiniProgram(project);
          break;
      }
    },
    // 切换web项目
    async changeWebProject(project) {
      if (this.isSsrProject(project)) {
        await this.setCurrentProject(project);
        return this.$router.push({
          path: "/app-main#/ssrProjectManage",
          query: merge(this.$route.query, { pid: project.id }),
        });
      }
      // 切换前先查看当前是否有编辑
      let res = sessionStorage.getItem("medusa_project_changeIsEdit");
      if (res) {
        res = JSON.parse(res);
        if (res.status) {
          MessageBox(`当前项目${res.env}环境代码已被修改是否放弃修改，切换项目`, "警告", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          }).then(async () => {
            res.status = false;
            sessionStorage.setItem("medusa_project_changeIsEdit", JSON.stringify(res));
            await this.setCurrentProject(project);
            this.$router.push({
              path: "/app-main#/",
              query: merge(this.$route.query, { pid: project.id }),
            });
          });
        } else {
          await this.setCurrentProject(project);
          this.$router.push({
            path: "/app-main#/",
            query: merge(this.$route.query, { pid: project.id }),
          });
        }
      } else {
        await this.setCurrentProject(project);
        this.$router.push({
          path: "/app-main#/",
          query: merge(this.$route.query, { pid: project.id }),
        });
      }
    },
    // 切换小程序项目
    async changeMiniProgram(project) {
      let slug;
      // 查询项目slug
      const res = await Api.getSlugByTypeAndId({
        project_type: project.project_type,
        id: project.id,
      });
      // 如果项目没有绑定slug，创建slug对应信息
      if (res.code === 200 && !res.data) {
        // 判断gitlabname中是否有数字
        const str = project.gitlabname.match(/\d+/g);
        if (str && str.length) {
          // 如果有数字
          const name = project.gitlabname.replace(/\d+/g, "");
          // slug规则为 mq + gitlabname非数字的部分 + 数字转换为字母的部分
          slug = `mp_${name}${name.length ? "_" : ""}${str
            .map((item) => this.number_to_word(item))
            .join("")}`;
        } else {
          // 如果没有数字
          // slug规则为 mq + gitlabname
          slug = `mp_${project.gitlabname}`;
        }
        // 创建slug对应关系
        const RS = await Api.createSlugRelation({
          project_type: project.project_type,
          id: project.id,
          slug,
        });
        // 如果创建失败 slug已经存在--再重新创建
        if (!RS) {
          slug = `${slug}_${this.number_to_word(+new Date())}`;
          const RR = await Api.createSlugRelation({
            project_type: project.project_type,
            id: project.id,
            slug,
          });
          // 如果第二次创建依旧失败则提示
          if (!RR) {
            return this.$message.warning("创建对应slug关系失败，请稍后尝试！");
          }
        }
      } else if (res.code === 200 && res.data) {
        slug = res.data.slug;
      }
      // 格式化参数
      const params = {
        ...project,
        project_name: project.projectname || "",
        gitlab_url: project.gitlaburl || "",
        gitlab_name: project.gitlabname || "",
        slug,
      };
      await this.setCurrentProject(params);
      this.$router.push({
        path: "/",
        query: merge(this.$route.query, { pid: project.id, type: project.project_type }),
      });
    },
    // 切换App项目
    async changeAppProject(project) {
      let slug;
      // 查询项目slug
      const res = await Api.getSlugByTypeAndId({
        project_type: project.project_type,
        id: project.id,
      });
      // 如果项目没有绑定slug，创建slug对应信息
      if (res.code === 200 && !res.data) {
        // 对appId去hash数字名称再转26字母
        slug = `app_${this.number_to_word(tool.getHashName(project.appId))}`;
        // 创建slug对应关系
        const RS = await Api.createSlugRelation({
          project_type: project.project_type,
          id: project.id,
          slug,
        });
        // 如果创建失败 slug已经存在--再重新创建
        if (!RS) {
          slug = `${slug}_${this.number_to_word(+new Date())}`;
          const RR = await Api.createSlugRelation({
            project_type: project.project_type,
            id: project.id,
            slug,
          });
          // 如果第二次创建依旧失败则提示
          if (!RR) {
            return this.$message.warning("创建对应slug关系失败，请稍后尝试！");
          }
        }
      } else if (res.code === 200 && res.data) {
        slug = res.data.slug;
      }
      // 格式化参数
      const params = {
        ...project,
        project_name: project.appName || "",
        gitlab_url: project.gitUrl || "",
        gitlab_id: project.gitProjectId || "",
        logo: project.logoUrl || "",
        slug,
      };
      await this.setCurrentProject(params);
      this.$router.push({
        path: "/",
        query: merge(this.$route.query, { pid: project.id, type: project.project_type }),
      });
    },
    // 切换离线包项目
    async changePackagesProject(project) {
      let slug;
      // 查询项目slug
      const res = await Api.getSlugByTypeAndId({
        project_type: project.project_type,
        id: project.id,
      });
      // 如果项目没有绑定slug，创建slug对应信息
      if (res.code === 200 && !res.data) {
        // 对appId去hash数字名称再转26字母
        slug = `pak_${this.number_to_word(tool.getHashName(project.name))}`;
        // 创建slug对应关系
        const RS = await Api.createSlugRelation({
          project_type: project.project_type,
          id: project.id,
          slug,
        });
        // 如果创建失败 slug已经存在--再重新创建
        if (!RS) {
          slug = `${slug}_${this.number_to_word(+new Date())}`;
          const RR = await Api.createSlugRelation({
            project_type: project.project_type,
            id: project.id,
            slug,
          });
          // 如果第二次创建依旧失败则提示
          if (!RR) {
            return this.$message.warning("创建对应slug关系失败，请稍后尝试！");
          }
        }
      } else if (res.code === 200 && res.data) {
        slug = res.data.slug;
      }
      // 格式化参数
      const params = {
        ...project,
        project_name: project.name || "",
        slug,
      };
      await this.setCurrentProject(params);
      this.$router.push({
        path: "/",
        query: merge(this.$route.query, { pid: project.id, type: project.project_type }),
      });
    },
    // 切换新离线包项目
    async changeNewPackagesProject(project) {
      let slug;
      // 查询项目slug
      const res = await Api.getSlugByTypeAndId({
        project_type: project.project_type,
        id: project.id,
      });
      // 如果项目没有绑定slug，创建slug对应信息
      if (res.code === 200 && !res.data) {
        // 对appId去hash数字名称再转26字母
        slug = `pvt_${this.number_to_word(tool.getHashName(project.name))}`;
        // 创建slug对应关系
        const RS = await Api.createSlugRelation({
          project_type: project.project_type,
          id: project.id,
          slug,
        });
        // 如果创建失败 slug已经存在--再重新创建
        if (!RS) {
          slug = `${slug}_${this.number_to_word(+new Date())}`;
          const RR = await Api.createSlugRelation({
            project_type: project.project_type,
            id: project.id,
            slug,
          });
          // 如果第二次创建依旧失败则提示
          if (!RR) {
            return this.$message.warning("创建对应slug关系失败，请稍后尝试！");
          }
        }
      } else if (res.code === 200 && res.data) {
        slug = res.data.slug;
      }
      // 格式化参数
      const params = {
        ...project,
        project_name: project.name || "",
        slug,
      };
      await this.setCurrentProject(params);
      this.$router.push({
        path: "/",
        query: merge(this.$route.query, { pid: project.id, type: project.project_type }),
      });
    },
    // 切换热更新项目
    async changeHotUpdateProject(project) {
      let slug;
      // 查询项目slug
      const res = await Api.getSlugByTypeAndId({
        project_type: project.project_type,
        id: project.id,
      });
      // 如果项目没有绑定slug，创建slug对应信息
      if (res.code === 200 && !res.data) {
        // 对appId去hash数字名称再转26字母
        slug = `hot_${this.number_to_word(tool.getHashName(project.appId))}`;
        // 创建slug对应关系
        const RS = await Api.createSlugRelation({
          project_type: project.project_type,
          id: project.id,
          slug,
        });
        // 如果创建失败 slug已经存在--再重新创建
        if (!RS) {
          slug = `${slug}_${this.number_to_word(+new Date())}`;
          const RR = await Api.createSlugRelation({
            project_type: project.project_type,
            id: project.id,
            slug,
          });
          // 如果第二次创建依旧失败则提示
          if (!RR) {
            return this.$message.warning("创建对应slug关系失败，请稍后尝试！");
          }
        }
      } else if (res.code === 200 && res.data) {
        slug = res.data.slug;
      }
      // 格式化参数
      const params = {
        ...project,
        project_name: project.appName || "",
        gitlab_id: project.gitProjectId || "",
        gitlab_url: project.gitUrl || "",
        logo: project.logoUrl || "",
        slug,
      };
      await this.setCurrentProject(params);
      this.$router.push({
        path: "/",
        query: merge(this.$route.query, { pid: project.id, type: project.project_type }),
      });
    },
    // 数字转26个字母
    number_to_word(x) {
      let s = "";
      while (x > 0) {
        let m = x % 26;
        m = m === 0 ? (m = 26) : m;
        s = String.fromCharCode(96 + parseInt(m)) + s;
        x = (x - m) / 26;
      }
      return s;
    },
    createProject() {
      this.$router.push("/newProject");
    },
    goback() {
      let queryObj = tool.getAllQueryParams();
      if (queryObj.pid) {
        this.$router.replace({
          path: "/app-main#/",
          query: merge(this.$route.query, { pid: queryObj.pid }),
        });
      } else {
        this.$router.go(-1);
      }
    },
    goBackEvnet(e) {
      if (e.keyCode === 27) {
        this.goback();
      }
    },
    async handleFavorite(project) {
      if (project.is_favorite) {
        const res = await Api.cancelFavoriteProject({ id: project.id });
        if (res.code === 200) {
          this.searchGroup();
          this.$message.info("取消收藏成功！");
        }
      } else {
        const res = await Api.addFavoriteProject({ id: project.id, slug: project.slug });
        if (res.code === 200) {
          this.searchGroup();
          this.$message.success("收藏成功！");
        }
      }
    },
    goToHead() {
      this.$refs["drawer"].scrollTo(0, 0);
    },
    // 显示收藏项目
    showFavoriteProject() {
      window.removeEventListener("keyup", this.goBackEvnet);
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      // 保证不会同时触发退出事件  dom绑定事件会先与数据修改执行
      setTimeout(() => {
        window.addEventListener("keyup", this.goBackEvnet);
      }, 500);
    },
    changeIdenty(value) {
      window.localStorage.setItem("medusa_p_identity", value);
      location.reload();
      // // 过滤项目
      // if (value === 'all') {
      //   this.list = this.listCopy
      // } else if (value === 'app') {
      //   this.list = this.listCopy.map(item=>item.p_type==='app' ||item.p_type==='packages'||item.p_type==='packages_v2'||item.p_type==='patch_app')
      // } else if (value === 'web') {
      //   this.list = this.listCopy.map(item=>!item.p_type)
      // } else if (value === 'mini') {
      //   this.list = this.listCopy.map(item=>item.p_type==='mini_program')
      // }
    },
  },
  destroyed() {
    window.removeEventListener("keyup", this.goBackEvnet);
  },
};
</script>

<style scoped>
:deep(.el-dialog) {
  width: 20% !important;
}
</style>
<style lang="scss">
.project-pannel {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #f2f3f5;

  .header-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 999;
  }
  .project-content {
    position: absolute;
    width: 100%;
    height: 90vh;
    top: 60px;
    left: 0;
    z-index: 998;
    overflow-y: auto;
    padding: 50px 30px;
    &-bg {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 1;
    }
    &-container {
      position: relative;
      min-height: calc(100% - 60px);
      z-index: 1000;
      padding: 15px 20px;
      background-color: #fff;
      box-shadow: 0 8px 10px -5px rgba(7, 128, 128, 0.05), 0 8px 12px 2px rgba(7, 128, 128, 0.06),
        0 6px 12px 5px rgba(7, 128, 128, 0.05) !important;
      background-color: #fff;
      background-color: #f8f5f2;
      overflow-y: auto;
    }
    .float-icon-box {
      position: fixed;
      display: flex;
      flex-flow: column wrap;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      .add,
      .search,
      .refresh,
      .aim,
      .close {
        padding: 10px;
        font-size: 20px;
        color: #4e5969;
        width: 56px;
        height: 56px;
        background-color: #fff;
        border-radius: 50%;
        text-align: center;
        line-height: 37px;
        cursor: pointer;
        // &::before {
        //   margin-top:-10px;
        //   top:10px;
        // }
      }
      .add,
      .refresh,
      .aim,
      .close {
        margin-top: 10px;
      }
      i:hover {
        color: #165dff !important;
        &::before {
          color: #165dff !important;
        }
      }
    }
    .card-header {
      position: fixed;
      // display: flex;
      // justify-content: flex-end;
      z-index: 999;
      width: 570px;
      left: 50%;
      top: 104px;
      margin-left: -300px;
      .search-input {
        display: flex;
        justify-content: space-around;
        width: 100%;
        .hig-input {
          border: 1px solid #165dff !important;
          border-radius: 2px;
        }
        .el-input__inner {
          border: 0;
          border-style: none;
        }
        input {
          outline: none;
          border: 0;
          border-style: none;
        }
        button {
          width: 109px;
          margin-left: 16px;
          background: #165dff;
          border-radius: 4px;
          height: 40px;
          color: white;
          font-size: 10px;
          font-family: "PingFang SC";
          font-weight: 400;
          border-radius: 4px;
        }
      }
    }
    .card-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 58px;
      .title {
        margin: 0 10px 20px 10px;
        width: 100%;
        color: #4e5969;
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
      }
      .card-item {
        position: relative;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        margin: 0 16px 16px 0;
        padding: 10px;
        width: 320px;
        height: 144px;
        // border: 1px solid #ebeef5;
        background-color: #ffffff;
        color: #303133;
        transition: 0.3s;
        border-radius: 8px;
        line-height: 1.5;
        padding: 16px, 16px, 16px, 24px;
        box-sizing: border-box;
        position: relative;
        .offline-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: 0 0 url(https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/offline-1.png)
            no-repeat transparent;
          background-size: 60px;
          cursor: not-allowed;
          z-index: 999;
        }
        &.isActive {
          &::before {
            width: 320px;
            height: 144px;
            content: "";
            border: 2px solid #c6d5f7;
            position: absolute;
            border-radius: 10px;
            left: -4px;
            top: 12;
          }
          border: 2px solid #165dff;
        }
        .favorite-icon {
          position: absolute;
          padding: 3px;
          right: 8px;
          bottom: 8px;
          font-size: 18px;
          color: #303133;
        }
        .color-yellow {
          color: #f7ba1e;
          font-size: 24px;
        }
        &:hover {
          box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
          cursor: pointer;
          .text {
            .name {
              color: #165dff;
            }
            .slug {
              color: #165dff;
            }
          }
        }
        &.active {
          background-image: url("https://front-xps-cdn.xsyx.xyz/2021/12/01/1613427798.png");
          background-repeat: no-repeat;
          background-position: right;
          background-size: contain;
          background-color: #dffaf3;
        }
        .tag {
          position: absolute;
          right: 0;
          top: 0;
          border-radius: 0;
          border-bottom-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        .logo {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 24px;
          color: white;
          width: 64px;
          height: 64px;
          border-radius: 50%;
          border: 1px dotted #ebeef5;
          background-color: #fff;
          // background: #d9d9d9;
          object-fit: contain;
          font-weight: 700;
        }
        .text {
          margin-left: 16px;
          width: calc(100% - 84px);
          .name {
            color: #4e5969;
            font-size: 18px;
            font-weight: 500;
          }
          .slug {
            font-size: 14px;
            font-weight: 400;
            color: #86909c;
          }
        }
      }
      .bcg-melons {
        // background-image: url("https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/APP (3).png");
        // background-repeat: no-repeat;
        // background-size: 80px 80px;
        // background-position: 50% 50%;
        .off-line-icon {
          font-size: 16px;
        }
      }
      .bcg-raven {
        // background-image: url("https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/nimi.png");
        // background-repeat: no-repeat;
        // background-size: 70px 70px;
        // background-position: 50% 50%;
        .off-line-icon {
          font-size: 16px;
        }
      }
      .type-iconf {
        position: absolute;
        padding: 3px;
        right: 8px;
        bottom: 8px;
        width: 24px;
        color: #303133;
      }
      .type-iconf-left {
        position: absolute;
        padding: 3px;
        left: 15px;
        bottom: 8px;
        width: 24px;
      }
    }
  }
  .love-project {
    max-height: 60vh;
    overflow: auto;
  }
}
.drawer-in {
  animation: drawer-in 0.3s;
}
.drawer-out {
  animation: drawer-out 0.6s;
}
@keyframes drawer-in {
  0% {
    transform: translate(0, -100%);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes drawer-out {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0, -100%);
  }
}
/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: #f8f5f2;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
.text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
