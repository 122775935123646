<template>
  <div class="app-form-container">
    <el-form size="small" :model="formData" ref="dialogForm" class="filter-area" @submit.native.prevent label-position="top" :rules="rules">
      <div class="flex-container">
        <div class="flex-items">
          <el-form-item label="App名称">
            <el-input v-model="formData.appName" placeholder="请输入App名称" autocomplete="off" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="AppId">
            <el-input v-model="formData.appId" placeholder="请输入App包名" autocomplete="off" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="别名">
            <el-input v-model="formData.alias" autocomplete="off" placeholder="请输入App别名或代码" size="medium"></el-input>
          </el-form-item>
          <el-form-item label="提取密码">
            <el-input v-model="formData.password" autocomplete="off" placeholder="请输入App提取密码" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="所属平台">
            <el-select v-model="formData.platform" placeholder="请选择所属平台" size="medium" :clearable="true">
              <el-option label="ANDROID" value="android"></el-option>
              <el-option label="IOS" value="ios"></el-option>
              <el-option label="PC" value="pc"></el-option>
            </el-select>
          </el-form-item>
          <!-- 关联应用 -->
          <el-form-item label="关联应用">
            <el-select size="medium" v-model="formData.associate" :placeholder="`请选择关联${
                  formData.platform == 'ios' ? 'Android' : 'iOS'
                }应用`" clearable filterable remote :remote-method="getAssociate">
              <el-option v-for="(item, index) in associateOptions" :key="index" :label="item.label" :value="item.value">
                <template>
                  <span style="float: left">{{ item.value }}</span>
                  <span style="
                        float: right;
                        color: #8492a6;
                        font-size: 13px;
                        margin-left: 10px;
                      ">{{ item.label }}</span>
                </template>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="flex-items">
          <el-form-item label="LOGO地址">
            <el-input v-model="formData.logoUrl" autocomplete="off" placeholder="请输入可访问的App的Logo地址" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="分发地址">
            <span style="font-size: 12px; color: #bbb" v-if="formData.platform == 'ios'">保存后自动生成</span>
            <el-input v-model="formData.appDistributionLink" placeholder="android可输入分发地址,如果托管在本平台,请留空. 保存后将自动生成." autocomplete="off" size="medium" v-else :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="App提取地址">
            <el-input v-model="formData.codeRedemptionLink" placeholder="ios请输入apple提取地址,android请留空在版本中维护下载地址" autocomplete="off" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="仓库地址" :label-width="formLabelWidth" prop="gitUrl">
            <el-input v-model="formData.gitUrl" placeholder="请输入gitlab仓库地址" autocomplete="off" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="仓库ID" :label-width="formLabelWidth" prop="gitProjectId">
            <el-input v-model.number="formData.gitProjectId" placeholder="请输入gitlab仓库id" autocomplete="off" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="formData.appDesc" autocomplete="off" placeholder="请输入备注" size="medium" type="textarea" :rows="10"></el-input>
          </el-form-item>
        </div>
        <div class="flex-items">
          <el-form-item label="开启变更企微通知" :label-width="formLabelWidth">
            <el-switch v-model="formData.enableWxPush"> </el-switch>
          </el-form-item>
          <el-form-item label="webhook" :label-width="formLabelWidth">
            <el-input v-model="formData.wxWebhook" placeholder="请输入企业微信通知webhook地址" autocomplete="off" size="medium" :clearable="true"></el-input>
          </el-form-item>
          <el-form-item label="开启多环境" :label-width="formLabelWidth">
            <el-switch v-model="formData.isEnableEnv" @change="forceUpdate">
            </el-switch>
          </el-form-item>
          <el-form-item label="开启升级推送" :label-width="formLabelWidth">
            <el-switch v-model="formData.isEnablePush" @change="forceUpdate">
            </el-switch>
          </el-form-item>
          <div class="getui-box" v-if="formData.isEnablePush == true">
            <el-form-item label="appId" :label-width="formLabelWidth">
              <el-input v-model="formData.getui.appId" placeholder="请输入个推appId" autocomplete="off" size="medium" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="appKey" :label-width="formLabelWidth">
              <el-input v-model="formData.getui.appKey" placeholder="请输入个推 appKey" autocomplete="off" size="medium" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="appSecret" :label-width="formLabelWidth">
              <el-input v-model="formData.getui.appSecret" placeholder="请输入个推 appSecret" autocomplete="off" size="medium" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="masterSecret" :label-width="formLabelWidth">
              <el-input v-model="formData.getui.masterSecret" placeholder="请输入个推 masterSecret" autocomplete="off" size="medium" :clearable="true"></el-input>
            </el-form-item>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { loading } from "@/plugins/loading";
import mApp from "../mApi";
import api from "../api";
export default {
  name: "appForm",
  props: {
    projectType: {
      type: String,
      default: 'web'
    },
    slug: {
      type: String,
      default: ''
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    "formData.platform": {
      handler (nv, ov) {
        if (nv != ov) {
          this.formData.associate = null;
          this.associateOptions = [];
        }
      },
    },
  },
  data () {
    return {
      formLabelWidth: "120px",
      formData: {
        appName: "",
        appId: "",
        alias: "",
        logoUrl: "",
        appDesc: "",
        password: "",
        platform: "ios",
        associate: null, //关联应用 appId
        isEnableEnv: true,
        isEnablePush: false,
        getui: {
          appId: "",
          appKey: "",
          appSecret: "",
          masterSecret: "",
        },
      },
      associateOptions: [],
      loading: false,
      rules: {
        appId: [{ required: true, message: "不能为空" }],
        platform: [{ required: true, message: "请选择" }],
      },
    };
  },
  computed: {
    isLoading: {
      get () {
        return this.submitLoading;
      },
      set (val) {
        this.$emit('update:submitLoading', val);
      }
    }
  },
  methods: {
    showLoading () {
      loading(true);
    },
    closeLoading () {
      loading(false);
    },
    forceUpdate () {
      this.$forceUpdate();
    },
    // 提交表单
    submitForm () {
      this.$refs.dialogForm.validate(async (valid) => {
        if (valid) {
          let res = await this.createApp();
          if (res.code === 200) {
            this.$message.success(res.message);
            this.$router.replace(`/project?pid=${res.data.id}&type=app`);
          }
        }
      });
    },
    // 提交创建App
    async createApp () {
      try {
        this.isLoading = true;
        const params = {
          ...this.formData,
          slug: this.slug,
          project_type: this.projectType,
        }
        return await api.createApp(params);
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.isLoading = false;
      }

    },
    async getAssociate (keyword) {
      if (this.formData.platform == "") {
        this.associateOptions = [];
        return;
      }
      let params = {
        key: keyword,
        pageSize: -1,
      };
      this.showLoading();
      let res = await mApp.getList(params);
      this.loading = false;
      if (res.code === 200) {
        if (!res || !res.data || !res.data.rows || res.data.rows.length == 0) {
          this.associateOptions = [];
        } else {
          this.associateOptions = res.data.rows
            .filter((app) => {
              return app.platform != this.formData.platform;
            })
            .map((app) => ({
              label: app.appName,
              value: app.appId,
            }));
        }
      } else {
        this.associateOptions = [];
      }
      this.closeLoading();
    },
  },
};
</script>

<style lang="scss" scoped>
.app-form-container {
  margin: 5px 0 5px 25px;
  max-height: calc(100% - 420px);
  ::v-deep .el-input {
    width: 260px;
  }
  ::v-deep .el-input-number {
    input {
      text-align: left;
    }
  }
  .flex-container {
    display: flex;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    .flex-items {
      flex: 1;
      &:last-child {
        margin: 0 10px;
      }
    }
  }
  .getui-box {
    border: 1px dashed #ccc;
    padding: 10px;
    border-radius: 8px;
  }
}
</style>