const stringHash = require("string-hash");


export function cloneRouterInfo (router) {
  const obj = {}
  for (let key in router) {
    if (key !== 'matched') {
      if (router[key]) {
        obj[key] = JSON.parse(JSON.stringify(router[key]))
      } else {
        obj[key] = null
      }
    }
  }
  return obj
}

/**
 * /*
 * 小件工具箱
 *
 * @format
 * @Author: yuzc
 * @Date: 2021-04-26 16:19:05
 * @Last Modified by: yuzc
 * @Last Modified time: 2022-08-11 16:03:00
 */

const Tools = {
  /**
   * 获取url参数
   * @param {*} name
   * @returns
   */
  getQueryString: (name) => {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    let r = window.location.search.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  },

  /**
   * 对两个对象的子对象进行合并
   * mergeObjects({a:{b:1,c:2}},{a:{b:2,c:2,d:3}}) =>  a:{b:2,c:2,d:3}
   * @param {*} target
   * @param {*} source
   * @returns
   */
  mergeObjects: (target, source) => {
    for (let sourceItem in source) {
      let sourceItemObj = source[sourceItem];
      if (target[sourceItem]) {
        target[sourceItem] = Object.assign(target[sourceItem], sourceItemObj);
      }
    }
    return target;
  },

  /**
   * 生成从minNum到maxNum的随机数
   * @param {*} minNum
   * @param {*} maxNum
   * @returns
   */
  randomNum (minNum, maxNum) {
    switch (arguments.length) {
      case 1:
        return parseInt(Math.random() * minNum + 1, 10);
        break;
      case 2:
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        break;
      default:
        return 0;
        break;
    }
  },

  /**
   * 生成uuid
   * @returns
   */
  uuid () {
    var s = [];
    var hexDigits = '0123456789abcdef';
    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = '4';
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
    s[8] = s[13] = s[18] = s[23] = '-';

    var uuid = s.join('');
    return uuid;
  },

  /**
   * 将秒格式化成 天 小时 分 秒
   * @param val
   * @returns {string}
   */
  formatSecond (val) {
    if (typeof val === 'string') {
      val = Number(val);
    }
    if (!val) {
      return '-';
    }
    let s = Math.floor(val % 60);
    val = parseInt(val / 60);
    let m = Math.floor(val % 60);
    val = parseInt(val / 60);
    let h = Math.floor(val % 24);
    val = parseInt(val / 24);
    let d = val;

    let result = `${s}秒`;
    if (m > 0) {
      result = `${m}分钟${result}`;
    }
    if (h > 0) {
      result = `${h}小时${result}`;
    }
    if (d > 0) {
      result = `${d}天${result}`;
    }
    return result;
  },
  /**
   * 防抖函数
   * @param fn
   * @param delay
   * @returns {(function(): void)|*}
   */
  debounce (fn, delay) {
    let timer;
    return () => {
      let args = arguments;
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    };
  },
  /**
   * 格式化时间
   * @param {*} date
   * @param {*} format
   * @returns
   */
  dateFormat (date, format) {
    function add_zero (x, length) {
      if (length == 2) {
        if (x < 10) {
          return `0${x}`;
        }

        return x;
      }

      if (x < 100) {
        return `000${x}`.substr(`${x}`.length);
      }

      return x;
    }

    date = date || new Date();
    date = new Date(date);
    let fmt = format || 'yyyy-MM-dd hh:mm:ss';

    let year = date.getFullYear();
    let month = add_zero(date.getMonth() + 1, 2);
    let day = add_zero(date.getDate(), 2);
    let hour = add_zero(date.getHours(), 2);
    let minute = add_zero(date.getMinutes(), 2);
    let second = add_zero(date.getSeconds(), 2);
    let milli_second = add_zero(date.getMilliseconds(), 3);

    if (fmt == 'yyyyMMddhh') {
      let date_str = `${year}${month}${day}${hour}`;
      return date_str;
    } else if (fmt == 'yyyyMMddhhmm') {
      let date_str = `${year}${month}${day}${hour}${minute}`;

      return date_str;
    } else if (fmt == 'yyyy-MM-dd hh:mm:ss') {
      let date_str = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

      return date_str;
    } else if (fmt == 'yyyy-MM-dd hh:mm:ss.S') {
      let date_str = `${year}-${month}-${day} ${hour}:${minute}:${second}.${milli_second}`;
      return date_str;
    } else if (fmt == 'MM/dd hh:mm') {
      let date_str = `${month}/${day} ${hour}:${minute}`;
      return date_str;
    }

    let o = {
      'M+': date.getMonth() + 1, // 月份
      'd+': date.getDate(), // 日
      'h+': date.getHours(), // 小时
      'm+': date.getMinutes(), // 分
      's+': date.getSeconds(), // 秒
      'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
      S: date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        `${date.getFullYear()}`.substr(4 - RegExp.$1.length)
      );
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length)
        );
    }

    return fmt;
  },
  // 复制一个内容到剪切板
  copyText (txt) {
    let textArea = document.createElement('textarea');
    textArea.style.background = 'transparent';
    textArea.value = txt;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      let success = document.execCommand('copy');
      textArea.blur();
      document.body.removeChild(textArea);
      if (success) {
        return true;
      } else {
        // setTimeout(() => {
        //   this.handleResetWindowScroll();
        // }, 1000);
        return true;
      }
    } catch (err) {
      textArea.blur();
      document.body.removeChild(textArea);
      return false;
    }
  },

  /**
   * 从URL获取所有参数
   * @returns {{}}
   */
  getAllQueryParams () {
    let str = window.location.href;
    if (!str) {
      return {};
    }
    let num = str.indexOf('?');
    str = str.substr(num + 1); // 取得所有参数
    const res = {};
    let name;
    let value;
    const arr = str.split('&'); // 各个参数放到数组里
    for (let i = 0; i < arr.length; i++) {
      num = arr[i].indexOf('=');
      if (num > 0) {
        name = arr[i].substring(0, num).trim();
        value = arr[i].substr(num + 1).trim();
        res[name] = value;
      }
    }
    return res;
  },

  /**
   * object转化成url拼接样式
   * @param obj 需要转化的参数
   */
  objToUrl (obj) {
    let arr = [];
    for (let i in obj) {
      if (obj.hasOwnProperty(i)) {
        arr.push(`${i}=${obj[i]}`);
      }
    }
    return `?${arr.join('&')}`;
  },

  /**
   * url转化成object拼接样式
   * @param urlStr 需要转化的参数
   */
  urlToObj (urlStr) {
    let string = '';
    if (urlStr.indexOf('?') === 0) {
      string = urlStr.substring(1);
    }
    string = string.split('&');
    let res = {};
    for (let i = 0; i < string.length; i++) {
      let str = string[i].split('=');
      if (str[0] != '') {
        res[str[0]] = str[1];
      }
    }
    return res;
  },

  /**
    * 获取hash名称
    * @param name 需要转化的参数
    */
  getHashName (name) {
    return stringHash(name.toString())
  }
};

export default Tools;
