<template>
  <div class="offline-package-form">
    <div class="form-box">
      <el-form ref="appForm" :model="appForm">
        <el-form-item label="包名" :label-width="formLabelWidth">
          <el-input v-model="appForm.name" placeholder="离线包在melons上的标识，建议使用中划线格式" autocomplete="off" size="medium" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="入口地址" :label-width="formLabelWidth">
          <el-input v-model="appForm.enterUrl" autocomplete="off" placeholder="请输入该离线包对应的入口地址" size="medium" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="GitLab项目名" :label-width="formLabelWidth">
          <el-input v-model="appForm.projectName" autocomplete="off" placeholder="一般使用Gitalb项目名（英文）" size="medium" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="备注" :label-width="formLabelWidth">
          <el-input v-model="appForm.desc" autocomplete="off" placeholder="请输入备注" size="medium" style="width: 300px"></el-input>
        </el-form-item>
        <el-form-item label="开启多环境" prop="isEnableEnv" :label-width="formLabelWidth">
          <el-switch v-model="appForm.isEnableEnv"> </el-switch>
        </el-form-item>
      </el-form>
    </div>
    <div class="drag-box">
      <el-upload ref="upload" class="upload-demo" drag action="/api/upload/file" :http-request="loadJsonFromFile" :multiple="false" :limit="1" accept="application/zip">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">只能上传zip文件</div>
      </el-upload>
    </div>
  </div>
</template>

<script>
import api from "../api";
export default {
  name: "offlinePackageForm",
  props: {
    projectType: {
      type: String,
      default: 'web'
    },
    slug: {
      type: String,
      default: ''
    },
    submitLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formLabelWidth: "120px",
      appForm: {
        name: "",
        desc: "",
        enterUrl: "",
        projectName: "",
      },
      blobUrl: null, // 上传的离线包Url
    };
  },
  computed: {
    isLoading: {
      get () {
        return this.submitLoading;
      },
      set (val) {
        this.$emit('update:submitLoading', val);
      }
    }
  },
  methods: {
    loadJsonFromFile: async function (files) {
      let that = this;
      var blobUrl = null;
      if (window.createObjcectURL != undefined) {
        blobUrl = window.createOjcectURL(files.file);
      } else if (window.URL != undefined) {
        blobUrl = window.URL.createObjectURL(files.file);
      } else if (window.webkitURL != undefined) {
        blobUrl = window.webkitURL.createObjectURL(files.file);
      }

      this.blobUrl = blobUrl;

      var JSZip = require("jszip");
      JSZip.loadAsync(files.file).then(function (files) {
        if (files.files["manifest_comm.json"]) {
          files.files["manifest_comm.json"]
            .async("string")
            .then(function (con) {
              console.log(con);
              try {
                let data = JSON.parse(con);
                that.appForm = {
                  name: data.projectName || "",
                  desc: data.desc || "",
                  enterUrl: data.entryURL || "",
                  projectName: data.projectName || "",
                };
              } catch (error) {
                console.log(error);
              }
            });
        } else {
          this.$message.warning("未检测到manifest.json文件");
        }
      });
    },
    // 提交表单
    submitForm () {
      this.$refs.appForm.validate(async (valid) => {
        if (valid) {
          let res = await this.createApp();
          if (res.code === 200) {
            this.$message.success(res.message);
            this.$router.replace(`/project?pid=${res.data.id}&type=packages`);
          }
        }
      });
    },
    // 提交创建App
    async createApp () {
      try {
        this.isLoading = true;
        const params = {
          ...this.appForm,
          slug: this.slug,
          project_type: this.projectType,
        }
        let res = await api.createPackage(params);
        this.blobUrl = null;
        return res;
      } catch (error) {
        console.log('error: ', error);
      } finally {
        this.isLoading = false;
      }
    },
    afterCreatePackage (values) {
      let pkg = values[0];
      let blobUrl = values[1];
      this.$confirm("是否继续创建离线包版本记录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (blobUrl) {
            this.$router.push({
              path: `/packages/${pkg.id}`,
              query: {
                blobUrl: blobUrl,
              },
            });
          } else {
            this.$router.push({ path: `/packages/${pkg.id}` });
          }
        })
        .catch(() => { });
    },
  },
};
</script>

<style lang="scss" scoped>
.offline-package-form {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding: 5px 25px 5px 0px;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .form-box {
    flex: 1;
    background-color: transparent;
  }
  .drag-box {
    flex: 1;
    margin-left: 30px;
    height: 200px;
    background-color: transparent;

    ::v-deep .el-upload-dragger {
      width: 300px;
      height: 150px;
    }

    ::v-deep .el-icon-upload {
      margin-top: 20px;
    }
  }
}
</style>