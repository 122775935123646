<template>
  <div class="favorite-project-container">
    <div class="card-content" v-if="myFavoriteProject.length">
      <div class="card-item" v-for="project in myFavoriteProject" :key="project.id" @click="handleChangeCurrentProjectEv(project)" :title="project.project_name">
        <div @click.stop="()=>{}" v-if="project.is_offline" class="offline-bg"></div>
        <el-tag class="tag" size="mini" v-if="project.template_type!=='default'" :type="getTypeClass(project.template_type)">{{project.template_type}}</el-tag>
        <img v-if="project.logo" class="logo" :src="project.logo" />
        <div v-else class="logo" :class="`color-${getColorName(project.project_name)}`">{{project.project_name[0]}}</div>
        <div class="text">
          <div class="name text-overflow-ellipsis">{{project.project_name + (project.is_offline?'（下线中）':'')}}</div>
          <div class="slug text-overflow-ellipsis">{{project.slug}}</div>
        </div>
        <i class="favorite-icon" :class="{'el-icon-star-off':!project.is_favorite,'el-icon-star-on color-yellow':project.is_favorite}" @click.stop="handleFavorite(project)" :title="project.is_favorite?'取消收藏':'收藏'"></i>
      </div>
    </div>
    <div v-else class="empty-content empty-image">
      <p class="empty-text">暂无收藏项目~</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data () {
    return {
      // projects: [],
      colorsName: ['blue', 'indigo', 'purple', 'pink', 'red', 'orange', 'yellow', 'green', 'teal', 'cyan', 'primary', 'info', 'warning', 'danger', 'light'],
    }
  },
  computed: {
    ...mapGetters(['myFavoriteProject']),
  },
  mounted () {

  },
  methods: {
    handleChangeCurrentProjectEv (project) {
      this.$emit('handleChangeCurrentProjectEv', project)
      this.$emit('close');
    },
    // 获取颜色名称
    getColorName (name) {
      return this.colorsName[name.charCodeAt(0) % this.colorsName.length];
    },
    getTypeClass (key) {
      let typeClass;
      switch (key) {
        case 'bp':
          typeClass = 'primary'
          break;
        case 'jarvis':
          typeClass = 'success'
          break;
        case 'ssr':
          typeClass = 'warning'
          break;
        case 'ssrActivity':
          typeClass = 'danger'
          break;
        default:
          typeClass = 'primary'
          break;
      }
      return typeClass;
    },
    handleFavorite (project) {
      this.$emit('handleFavorite', project)
    }
  }
}
</script>

<style lang="scss" scoped>
.favorite-project-container {
  .card-content {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;
    .card-item {
      position: relative;
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      margin: 0 16px 16px 0;
      padding: 10px;
      width: 320px;
      height: 144px;
      // border: 1px solid #ebeef5;
      background: #f5f7fa;
      color: #303133;
      transition: 0.3s;
      border-radius: 8px;
      line-height: 1.5;
      padding: 16px, 16px, 16px, 24px;
      box-sizing: border-box;
      position: relative;
      .offline-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: 0 0
          url(https://front-xps-cdn.xsyx.xyz/custom/medusa/icon/offline-1.png)
          no-repeat transparent;
        background-size: 60px;
        cursor: not-allowed;
        z-index: 999;
      }
      &.isActive {
        &::before {
          width: 320px;
          height: 144px;
          content: "";
          border: 2px solid #c6d5f7;
          position: absolute;
          border-radius: 10px;
          left: -4px;
          top: 12;
        }
        border: 2px solid #165dff;
      }
      .favorite-icon {
        position: absolute;
        padding: 3px;
        right: 8px;
        bottom: 8px;
        font-size: 18px;
        color: #303133;
      }
      .color-yellow {
        color: #f7ba1e;
        font-size: 24px;
      }
      &:hover {
        box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
        cursor: pointer;
        .text {
          .name {
            color: #165dff;
          }
          .slug {
            color: #165dff;
          }
        }
      }
      &.active {
        background-image: url("https://front-xps-cdn.xsyx.xyz/2021/12/01/1613427798.png");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;
        background-color: #dffaf3;
      }
      .tag {
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0;
        border-bottom-left-radius: 8px;
        border-top-right-radius: 8px;
      }
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        color: white;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        border: 1px dotted #ebeef5;
        background-color: #fff;
        // background: #d9d9d9;
        object-fit: contain;
        font-weight: 700;
      }
      .text {
        width: calc(100% - 84px);
        margin-left: 16px;
        .name {
          color: #4e5969;
          font-size: 18px;
          font-weight: 500;
        }
        .slug {
          font-size: 14px;
          font-weight: 400;
          color: #86909c;
        }
      }
    }
  }
  .empty-content.empty-image {
    height: 400px;
    background-size: 60%;
    background-image: url("https://front-xps-cdn.xsyx.xyz/custom/xps/images/favorite-4.png");
    background-repeat: no-repeat;
    background-position: center;
  }
  .empty-text {
    position: relative;
    top: 300px;
    font-weight: 500;
    color: #bcbcbc;
    text-align: center;
  }
}
</style>