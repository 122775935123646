import DynamicStore from '@lmio/dynamic-store';
import ProjectApi from '@/views/project/api.js'
import EventBus from '../../plugins/eventBus'
const state = {
  groupList: [],
  // 当前高亮分组
  currentGroup: {},
  // 所有的项目列表
  projectList: [],
  // 当前操作的项目基本信息
  currentProject: {},
  // 当前项目的版本列表
  currentProjectVersionList: [],
  // 当前操作的项目版本信息
  currentProjectVersion: {},
  // 当前操作的项目-版本下的page列表
  currentProjectVersionPage: [],
  // 当前项目已绑定的环境列表
  currentProjectEnvList: [],
  // 当前操作的环境dev,uat,pre,prod
  currentProjectEnv: 'dev',
  // 当前项目的标签列表
  currentProjectTags: [],
  // 当前项目的用户角色列表
  currentProjectUserRole: [],
  // 是否在编辑当前项目下的某个html_code
  currentProjectIsEdit: {
    dev: {
      status: false, // 修改状态
      html_code: '', // 修改后的代码
      bpconfig: '',  // 修改后的config
      bpconfigStatus: false //修改状态
    },
    uat: {
      status: false,
      html_code: '',
      bpconfig: '',  // 修改后的config
      bpconfigStatus: false //修改状态
    },
    pre: {
      status: false,
      html_code: '',
      bpconfig: '',  // 修改后的config
      bpconfigStatus: false //修改状态
    }
  },
  versionListLoading: false, // 版本列表区域loading
  editorLoading: false, // 工作区域loading
  searchProjectString: '', // 搜索项目列表关键字
  searchVersionString: '', // 版本搜索列表关键字
  refreshProjectList: true, // 是否需要刷新项目列表
  currentHistoryVersionPageId: '',
  refreshHistoryFlag: true, // 是否刷新历史记录
  refreshConfigCenterFlag: true, // 刷新配置中心
  myFavoriteProject: [] // 我收藏的项目
};


const actions = {
  async setCurrentProject ({ commit }, view) {
    console.log('正在设置项目信息！');
    EventBus.$emit('projectChange', view)
    await commit('currentProject@persistence', view);
  },
  // 获取项目详情
  async getProjectDetail (ctx, payload) {
    try {
      // 如果有项目参数
      if (payload.project_id) {
        console.log('正在获取项目详情！');
        let _res;
        if (payload.project_type === 'web') {
          _res = await ProjectApi.projectDetail({ project_id: payload.project_id });
        } else {
          let params = {
            id: payload.project_id,
            project_type: payload.project_type
          };
          _res = await ProjectApi.getInfoByIdAndType(params);
          _res.data = formatParamsByProjectType(_res.data)
        }
        ctx.dispatch('setCurrentProject', _res.data);
        // 判断是否为ssr项目
        if (payload.judgeSSR && _res.data && _res.data.template_type === 'ssr' || _res.data.template_type === 'ssrActivity') {
          window.location.href = `${window.location.origin}/app-main?pid=${payload.project_id}#/ssrProjectManage`;
        }
        return true;
      }
    } catch (e) {
      console.warn(e.message);
      return false;
    }
  },
  // 更新项目列表信息
  async refreshGroupList ({ commit }) {
    let rows = await ProjectApi.list();
    let childList = await ProjectApi.getChildList();
    let favoriteList = [];
    const list = rows.data;
    rows.data.forEach(item => {
      favoriteList.push(...item.projects.filter(p => p.is_favorite))
    })
    if (childList.data.length > 0) {
      const diyGroup = {
        group_name: "我的应用",
        id: 999,
        project_number: childList.data.length,
        projects: childList.data,
        type: "group"
      }
      list.push(diyGroup)
      favoriteList.push(...childList.data.filter(p => p.is_favorite))
    }
    commit('groupList@persistence', list)
  }
}

// 根据项目类型格式化参数  补齐currentProject参数
function formatParamsByProjectType (project) {
  let params;
  switch (project.project_type) {
    case 'app':
      params = {
        ...project,
        project_name: project.appName || '',
        gitlab_url: project.gitUrl || '',
        gitlab_id: project.gitProjectId || '',
        logo: project.logoUrl || '',
      }
      break;
    case 'packages':
      params = {
        ...project,
        project_name: project.name || '',
      }
      break;
    case 'packages_v2':
      params = {
        ...project,
        project_name: project.name || '',
      }
      break;
    case 'patch_app':
      params = {
        ...project,
        project_name: project.appName || '',
        gitlab_id: project.gitProjectId || '',
        gitlab_url: project.gitUrl || '',
        logo: project.logoUrl || '',
      }
      break;
    default:
      params = {
        ...project,
        project_name: project.projectname || '',
        gitlab_url: project.gitlaburl || '',
        gitlab_name: project.gitlabname || '',
      }
      break;
  }
  return params;
}

// tips 需要提前执行 先于state初始化
const projectDS = new DynamicStore(state, {
  actions,
  persistence: true,
  mutationController: ['set', 'persistence']
});

export default projectDS.exportModule();


