<template>
  <div>
    <div id="app" class="base-app">
      <!-- <div id="nav">
			<router-link to="/">Home</router-link> |
			<router-link to="/about">About</router-link> |
			<router-link to="/xpss">VueApp</router-link>
		</div> -->
      <!-- <keep-alive> -->
      <router-view />
      <!-- </keep-alive> -->
      <!--新增部分，用于承载子应用-->
    </div>
    <div>
      <!-- <div id="keepaliveApp">keepaliveApp</div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import watermark from "./utils/watermark";
import tool from "./utils/tool";
export default {
  async created() {
    const token = window.localStorage.getItem("NEW_TOKEN");
    if (token) {
      const token_info = JSON.parse(token);
      this.setToken(`${token_info.token_type} ${token_info.access_token}`);
    } else if (!["/home"].includes(this.$route.path) && !token) {
      this.$router.push("/home");
    }
  },
  data() {
    return {
      userInfo: {},
    };
  },
  methods: {
    ...mapActions("user", ["setUserInfo", "setToken", "getUserInfo"]),
  },
  computed: {
    ...mapGetters("project", ["currentProject"]),
  },
  mounted() {
    // 新增事件监听
    this.$eventBus.$on("refreshProject", async (data) => {
      console.log("基座工程更新项目中...");
      if (typeof data === "object") {
        let { pid, project_id, project_type = "web", refreshGroupList } = data;
        // 是否刷新列表
        if (refreshGroupList) {
          console.log("基座工程更新项目列表中...");
          this.$store.dispatch("project/refreshGroupList");
        }
        await this.$store.dispatch("project/getProjectDetail", {
          project_id: pid || project_id,
          judgeSSR: true,
          project_type,
        });
      } else {
        await this.$store.dispatch("project/getProjectDetail", {
          project_id: data,
          judgeSSR: true,
          project_type: "web",
        });
      }
    });
  },
  beforeDestroy() {
    // 关闭事件监听
    this.$eventBus.$off("refreshProject");
  },
  watch: {
    // 监听路由变化
    $route: {
      immediate: true,
      async handler(n, o) {
        const token = window.localStorage.getItem("NEW_TOKEN");

        if (n.fullPath && n.fullPath.startsWith("/app-") && token) {
          // 判断路由中是否存在pid web项目类型适用
          if (!o && n.query.pid) {
            await this.$store.dispatch("project/getProjectDetail", {
              project_id: n.query.pid,
              judgeSSR: true,
              project_type: n.query.type || "web",
            });
          } else if (!o) {
            // 因为其他项目类型会重定向 路由参数会拼接到后面 一般的route.query拿不到参数 所以需要自己拼接参数
            const obj = tool.urlToObj(location.href.slice(location.href.lastIndexOf("?")));
            // 如果有项目id和类型 其他项目类型适用
            if (obj.pid && obj.type) {
              await this.$store.dispatch("project/getProjectDetail", {
                project_id: obj.pid,
                judgeSSR: false,
                project_type: obj.type,
              });
            } else if (!this.currentProject.id) {
              this.$alert("没有选择项目，请先选择项目再操作", "请选择项目", {
                confirmButtonText: "确定",
                type: "warning",
                showClose: false,
                callback: (action) => {
                  if (action === "confirm") {
                    this.$router.push("/project");
                  }
                },
              });
            }
          }
        }
      },
    },
  },
};
</script>
